import { Campaign } from '../../interfaces'
import { CampaignGroup } from '../../interfaces/CampaignGroup'
import { defaultTransformFn, platformApi } from './platformApi'

const campaignGroupsTags = platformApi.enhanceEndpoints({ addTagTypes: ['CampaignGroups', 'CampaignGroup', 'CampaignGroupCampaigns'] })

export const campaignGroupsApi = campaignGroupsTags.injectEndpoints({
  endpoints: build => ({
    getCampaignGroups: build.query<CampaignGroup[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: `campaignGroups`,
        method: 'GET',
        params: {
          accountId
        },
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'CampaignGroups', id: request.accountId }]
    }),
    getCampaignGroup: build.query<CampaignGroup, { campaignGroupId: string }>({
      query: ({ campaignGroupId }) => ({
        url: `campaignGroups/${campaignGroupId}`,
        method: 'GET',
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'CampaignGroup', id: request.campaignGroupId }]
    }),
    getCampaignGroupCampaigns: build.query<Campaign[], { campaignGroupId: string }>({
      query: ({ campaignGroupId }) => ({
        url: `campaignGroups/${campaignGroupId}/campaigns`,
        method: 'GET',
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'CampaignGroupCampaigns', id: request.campaignGroupId }]
    }),
    updateCampaignGroup: build.mutation<
      CampaignGroup,
      {
        campaignGroupId: string
        data: {
          name?: string
          autoActivate?: boolean
          paymentMethodId?: string
          activateDaysBeforeStartDate?: number
          updatedBy: string
        }
      }
    >({
      query: ({ campaignGroupId, data }) => ({
        url: `campaignGroups/${campaignGroupId}`,
        method: 'POST',
        data,
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (_result, _error, { campaignGroupId }) => [{ type: 'CampaignGroup', id: campaignGroupId }]
    })
  })
})

export const { useGetCampaignGroupsQuery, useGetCampaignGroupQuery, useGetCampaignGroupCampaignsQuery, useUpdateCampaignGroupMutation } = campaignGroupsApi
