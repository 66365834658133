import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiBadge, EuiButtonIcon, EuiConfirmModal, EuiContextMenuItem, EuiContextMenuPanel, EuiEmptyPrompt, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLink, EuiLoadingSpinner, EuiOverlayMask, EuiPopover, EuiSpacer, EuiSwitch, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { apiPostCampaignDuplicate } from 'api/adcritterApi'
import { Campaign, CampaignActiveStatus, CampaignStatus, WhiteLabel } from 'api/interfaces'
import { campaignsApi, useGetEndingOnDateByCampaignIdQuery, useReactivateCampaignMutation } from 'api/rtkQueryApi/platform/campaignsApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AcEuiInMemoryTableWithSearch } from 'components/Basic/AcEuiInMemoryTableWithSearch'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { AcPaymentModal } from 'components/Modals/AcPaymentModal'
import history from 'services/HistoryService'
import { IAcDictionary } from 'utils/AcDictionary'
import { CampaignStatusToElasticColor, CampaignStatusToString, formatEndingOnDate } from 'utils/EnumToFriendly'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { setCampaign } from '../campaign/campaignSlice'
import { archiveCampaign, CampaignState, pauseCampaign, removeCampaign, startCampaign, unarchiveCampaign } from './campaignsSlice'

type CampaignsListProps = {
  campaigns: Campaign[]
  isLoading: boolean
}

const CampaignsList = ({ campaigns, isLoading }: CampaignsListProps) => {
  const dispatch = useDispatch()
  const [reactivateCampaign, reactivateCampaignQuery] = useReactivateCampaignMutation()
  const { currentAccount, isVendastaUser, isVendastaPartnerUser, isShopifyUser } = useSelector((state: RootState) => state.app)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [message, setMessage] = useState<ReactNode>('')
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [isCancelPlanCampaignModalVisible, setIsCancelPlanCampaignModalVisible] = useState(false)
  const [duplicateCampaignName, setDuplicateCampaignName] = useState('')
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false)
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false)
  const [isUnarchiveModalVisible, setIsUnarchiveModalVisible] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null)
  const [isUnpausePaymentModalVisible, setUnpausePaymentModalVisible] = useState(false)
  const [campaignIdToUnpause, setCampaignIdToUnpause] = useState<string | null>(null)
  const [showingPauseCampaignModal, setShowingPauseCampaignModal] = useState<boolean>(false)
  const [popoverOpens, setPopoverOpens] = useState<IAcDictionary>({})
  const [amount, setAmount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const whiteLabel = useWhiteLabel()
  const [showEndedCampaigns, setShowEndedCampaigns] = useState(true)
  const [showArchivedCampaigns, setShowArchivedCampaigns] = useState(false)
  const [campaignStatesAll, setCampaignStatesAll] = useState<CampaignState[]>([])
  const [campaignStates, setCampaignStates] = useState<CampaignState[]>([])
  const whiteLabelType = whiteLabel?.getWhiteLabel()

  // const getCampaignsQuery = useGetCampaignsQuery(
  //   {
  //     accountId: currentAccount?.id ?? '',
  //     isAgency: whiteLabel?.isAgencies()
  //   },
  //   { refetchOnMountOrArgChange: true }
  // )

  const { isFetching: isFetchingEndingOnDate, data: endingOnDate } = useGetEndingOnDateByCampaignIdQuery(
    { accountId: currentAccount?.id ?? '', campaignId: selectedCampaign ?? '', isAgency: whiteLabel?.isAgencies() },
    {
      skip: !whiteLabel || !showingPauseCampaignModal || !selectedCampaign || !currentAccount
    }
  )

  useEffect(() => {
    setCampaignStatesAll(
      campaigns.map(c => ({
        id: c.id,
        checked: c.activeStatus === CampaignActiveStatus.Active && c.status !== CampaignStatus.EndingOn,
        canRemove: c.canRemove,
        canUnpauseWithoutPayment: c.canUnpauseWithoutPayment,
        canArchive: c.canArchive,
        disabled: !c.canEditStatus,
        name: c.name,
        budget: c.budget,
        budgetFrequency: c.budgetFrequency,
        loading: false,
        status: c.status,
        type: c.type,
        hasBundle: c.hasBundle,
        endingOnDate: c.endingOnDate,
        isMatching: c.isMatching
      }))
    )
  }, [campaigns])

  useEffect(() => {
    setCampaignStates(
      campaignStatesAll.filter(c => {
        if (!showEndedCampaigns && c.status === CampaignStatus.CampaignEnded) return false
        if (!showArchivedCampaigns && c.status === CampaignStatus.Archived) return false
        return true
      })
    )
  }, [campaignStatesAll, showEndedCampaigns, showArchivedCampaigns])

  const confirmRemoveModal = () => {
    if (selectedCampaign && currentAccount) {
      dispatch(removeCampaign(currentAccount.id, selectedCampaign))
      setIsRemoveModalVisible(false)
      setSelectedCampaign(null)
    }
  }

  const showRemoveModal = (campaignId: string) => {
    setIsRemoveModalVisible(true)
    setSelectedCampaign(campaignId)
  }
  const showCancelPlanCampaignModal = (campaignId: string) => {
    setIsCancelPlanCampaignModalVisible(true)
    setSelectedCampaign(campaignId)
  }

  const showDuplicateModal = (campaignId: string) => {
    setIsDuplicateModalVisible(true)
    setSelectedCampaign(campaignId)
    let campaign = campaigns.filter((obj: Campaign) => {
      return obj.id === campaignId
    })
    if (campaign.length === 1) {
      setDuplicateCampaignName(`Copy of ${campaign[0].name}`)
    }
  }

  const cancelDuplicateModal = () => {
    setDuplicateCampaignName('')
    setIsDuplicateModalVisible(false)
    setSelectedCampaign(null)
  }

  const confirmDuplicateModal = () => {
    setIsDuplicateLoading(true)
    if (duplicateCampaignName !== '' && selectedCampaign) {
      apiPostCampaignDuplicate(currentAccount!.id, selectedCampaign, duplicateCampaignName)
        .then(_ => {
          dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: currentAccount!.id }]))
          cancelDuplicateModal()
        })
        .finally(() => {
          setIsDuplicateLoading(false)
        })
    } else {
      setIsDuplicateLoading(false)
    }
  }
  let duplicateModal
  if (isDuplicateModalVisible) {
    duplicateModal = (
      <EuiOverlayMask>
        <EuiConfirmModal title='Duplicate Campaign' onCancel={cancelDuplicateModal} onConfirm={confirmDuplicateModal} isLoading={isDuplicateLoading} cancelButtonText='Cancel' confirmButtonText='Confirm' buttonColor='primary' confirmButtonDisabled={isDuplicateLoading}>
          <EuiForm component='form'>
            <EuiFormRow label='Campaign Name' fullWidth>
              <EuiFieldText
                name='duplicateCampaignName'
                value={duplicateCampaignName}
                onChange={e => {
                  setDuplicateCampaignName(e.target.value)
                }}
                placeholder=''
                fullWidth
              />
            </EuiFormRow>
          </EuiForm>
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  const showArchiveModal = (campaignId: string) => {
    setIsArchiveModalVisible(true)
    setSelectedCampaign(campaignId)
  }

  const confirmArchiveModal = () => {
    if (selectedCampaign && currentAccount) {
      dispatch(archiveCampaign(currentAccount.id, selectedCampaign))
      setIsArchiveModalVisible(false)
      setSelectedCampaign(null)
    }
  }

  const showUnarchiveModal = (campaignId: string) => {
    setIsUnarchiveModalVisible(true)
    setSelectedCampaign(campaignId)
  }

  const confirmUnarchiveModal = () => {
    if (selectedCampaign && currentAccount) {
      dispatch(unarchiveCampaign(currentAccount.id, selectedCampaign))
      setIsUnarchiveModalVisible(false)
      setSelectedCampaign(null)
    }
  }

  const cancelUnpausePaymentModal = () => setUnpausePaymentModalVisible(false)

  const confirmUnpausePaymentModal = (paymentMethodId: string) => {
    if (currentAccount && campaignIdToUnpause) {
      reactivateCampaign({
        accountId: currentAccount.id,
        campaignId: campaignIdToUnpause,
        paymentMethodId: paymentMethodId
      }).then(() => {
        setUnpausePaymentModalVisible(false)
        setCampaignIdToUnpause(null)
      })
    }
  }

  const showUnpauseModal = (campaignState: CampaignState) => {
    if (currentAccount && campaignState) {
      setAmount(campaignState.budget)
      setCampaignIdToUnpause(campaignState.id)
      setUnpausePaymentModalVisible(true)
    }
  }

  const statusToHealth = (status: CampaignStatus) => {
    return CampaignStatusToElasticColor(status)
  }

  const confirmPauseCampaign = () => {
    if (selectedCampaign && currentAccount) {
      dispatch(pauseCampaign(currentAccount!.id, selectedCampaign))
      dispatch(showSuccessToast('Successfully paused your campaign'))
      setShowingPauseCampaignModal(false)
    }
  }

  const togglePopover = (campaignId: string) => {
    setPopoverOpens(popoverOpens => {
      popoverOpens[campaignId] = !popoverOpens[campaignId]
      return { ...popoverOpens }
    })
  }

  const createContextMenuItem = (c: CampaignState) => {
    let items = []

    if (!isVendastaUser && !isShopifyUser && !c.hasBundle) {
      items.push(
        <EuiContextMenuItem
          key='A'
          icon='copy'
          onClick={() => {
            showDuplicateModal(c.id)
          }}>
          Duplicate
        </EuiContextMenuItem>
      )
    }

    if (c.canArchive) {
      items.push(
        <EuiContextMenuItem
          key='B'
          icon='submodule'
          onClick={() => {
            showArchiveModal(c.id)
          }}>
          Archive
        </EuiContextMenuItem>
      )
    }

    if (c.status === CampaignStatus.Archived) {
      items.push(
        <EuiContextMenuItem
          style={c.status !== CampaignStatus.Archived ? { display: 'none' } : {}}
          key='C'
          icon='editorUndo'
          onClick={() => {
            showUnarchiveModal(c.id)
          }}>
          Unarchive
        </EuiContextMenuItem>
      )
    }

    if (c.canRemove && !isVendastaUser) {
      items.push(
        <EuiContextMenuItem
          style={!c.canRemove ? { display: 'none' } : {}}
          key='D'
          icon='cross'
          onClick={() => {
            showRemoveModal(c.id)
          }}>
          Remove
        </EuiContextMenuItem>
      )
    }

    if (isShopifyUser) {
      items.push(
        <EuiContextMenuItem
          key='D'
          icon='cross'
          onClick={() => {
            showCancelPlanCampaignModal(c.id)
          }}>
          Cancel
        </EuiContextMenuItem>
      )
    }
    return items
  }

  const columns: Array<EuiBasicTableColumn<CampaignState>> = [
    {
      width: '90px',
      render: (c: CampaignState) => (
        <React.Fragment>
          <EuiSwitch
            showLabel={false}
            label={''}
            checked={c.checked}
            disabled={c.disabled || c.hasBundle}
            onChange={e => {
              if (e.target.checked) {
                if (c.canUnpauseWithoutPayment) {
                  dispatch(startCampaign(currentAccount!.id, c.id))
                  dispatch(showSuccessToast('Successfully restarted your campaign'))
                } else {
                  showUnpauseModal(c)
                }
              } else {
                setShowingPauseCampaignModal(true)
                setSelectedCampaign(c.id)
              }
            }}
          />
          {(c.loading || (showingPauseCampaignModal && isFetchingEndingOnDate && c.id === selectedCampaign)) && <EuiLoadingSpinner style={{ marginLeft: 5 }} />}
        </React.Fragment>
      )
    },
    {
      width: '90px',
      name: `Status`,
      render: (c: CampaignState) => <EuiBadge color={statusToHealth(c.status)}>{CampaignStatusToString(c)}</EuiBadge>,
      sortable: (c: CampaignState) => c.status
    },
    {
      width: '150px',
      name: 'Campaign',
      render: (c: CampaignState) => (
        <EuiLink className={c.type} onClick={() => onCampaignClick(c.id)} color='success'>
          {c.name}
          {c.hasBundle && !c.isMatching ? (
            <>
              {' '}
              <EuiBadge color='accent'>Plan</EuiBadge>
            </>
          ) : (
            ''
          )}
          {c.isMatching ? (
            <>
              {' '}
              <EuiBadge color='success'>Matching</EuiBadge>
            </>
          ) : (
            ''
          )}
        </EuiLink>
      ),
      sortable: (c: CampaignState) => c.name
    },
    {
      width: '150px',
      name: `Type`,
      render: (c: CampaignState) => <div className='campaignType'>{c.type}</div>,
      sortable: (c: CampaignState) => c.type
    },
    {
      width: '150px',
      name: `Budget`,
      render: (c: CampaignState) => formatter.format(c.budget) + (whiteLabel?.isAgencies() ? ' ' + c.budgetFrequency.toLowerCase() : ''),
      sortable: (c: CampaignState) => c.budget
    },
    {
      width: '50px',
      render: (c: CampaignState) => (
        <React.Fragment>
          <EuiPopover button={<EuiButtonIcon aria-label='Actions' iconType='gear' size='s' color='text' onClick={() => togglePopover(c.id)} />} isOpen={popoverOpens[c.id]} closePopover={() => togglePopover(c.id)} panelPaddingSize='none' anchorPosition='leftCenter'>
            <EuiContextMenuPanel items={createContextMenuItem(c)} />
          </EuiPopover>
        </React.Fragment>
      )
    }
  ]

  if (isVendastaUser && !isVendastaPartnerUser) {
    columns.splice(
      columns.findIndex(c => c.name === 'Budget'),
      1
    )
  }

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'campaignName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100],
    pageIndex: currentPage
  }

  const onCampaignClick = (id: string) => {
    const campaign = campaigns.find(c => c.id === id)
    if (!campaign) {
      return
    }
    dispatch(setCampaign(campaign))

    history.push(`/campaigns/edit/${campaign.id}/details`)
  }

  return (
    <React.Fragment>
      <AcEuiInMemoryTableWithSearch
        loading={isLoading}
        items={campaignStates}
        columns={columns}
        message={isLoading ? 'Loading campaigns...' : 'No campaigns'}
        pagination={pagination}
        sorting={sorting}
        searchFields={['status', 'name', 'type']}
        onChange={(criteria: any) => {
          setCurrentPage(criteria.page.index)
        }}
        search={{
          toolsRight: [
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiSwitch onChange={() => setShowEndedCampaigns(!showEndedCampaigns)} label={'Show Ended'} checked={showEndedCampaigns} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSwitch onChange={() => setShowArchivedCampaigns(!showArchivedCampaigns)} label={'Show Archived'} checked={showArchivedCampaigns} />
              </EuiFlexItem>
            </EuiFlexGroup>
          ],
          box: {
            incremental: true
          }
        }}
      />
      {showingPauseCampaignModal && !isFetchingEndingOnDate && endingOnDate && <AcConfirmModal message={`Unless the campaign is resumed your advertisements will stop after ${formatEndingOnDate(endingOnDate)}.`} title='Are you sure you want to pause this campaign?' onCancel={() => setShowingPauseCampaignModal(false)} onConfirm={confirmPauseCampaign} cancelButtonText="No, don't do it" confirmButtonText='Yes, do it' defaultFocusedButton='confirm' buttonColor='primary' />}
      {isRemoveModalVisible && <AcConfirmModal message='Once you do this, this campaign will be removed and you will no longer have access to it.' title='Are you sure you want to remove this campaign?' onCancel={() => setIsRemoveModalVisible(false)} onConfirm={confirmRemoveModal} cancelButtonText="No, don't do it" confirmButtonText='Yes, do it' defaultFocusedButton='confirm' buttonColor='primary' />}
      {isCancelPlanCampaignModalVisible && <AcConfirmModal message='' title='Please reach out to AdCritter to cancel this campaign.' onCancel={() => setIsCancelPlanCampaignModalVisible(false)} onConfirm={() => setIsCancelPlanCampaignModalVisible(false)} cancelButtonText='Close' confirmButtonText='Understood' defaultFocusedButton='confirm' buttonColor='primary' />}
      {duplicateModal}
      {isArchiveModalVisible && <AcConfirmModal message='Archived campaigns do not show up on the dashboard. If you change your mind later, you can un-archive it anytime.' title='Are you sure you want to archive this campaign?' onCancel={() => setIsArchiveModalVisible(false)} onConfirm={confirmArchiveModal} cancelButtonText="No, don't do it" confirmButtonText='Yes, do it' defaultFocusedButton='confirm' buttonColor='primary' />}
      {isUnarchiveModalVisible && <AcConfirmModal message='This will restore the campaign on the dashboard and allow you to un-pause it if you wish.' title='Are you sure you want to un-archive this campaign??' onCancel={() => setIsUnarchiveModalVisible(false)} onConfirm={confirmUnarchiveModal} cancelButtonText="No, don't do it" confirmButtonText='Yes, do it' defaultFocusedButton='confirm' buttonColor='primary' />}
      {isUnpausePaymentModalVisible && (
        <AcPaymentModal
          title='Are you sure you want to unpause this campaign?'
          message={`To restart this campaign, we need to charge your payment method ${amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })}.`}
          onCancel={cancelUnpausePaymentModal}
          onConfirm={confirmUnpausePaymentModal}
          cancelButtonText='Cancel'
          confirmButtonText={`Pay ${amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })} Now`}
          isLoading={reactivateCampaignQuery.isLoading}
        />
      )}
    </React.Fragment>
  )
}

export default CampaignsList
