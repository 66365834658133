import { BillboardGeo, SegmentTarget, ThirdPartyAudience } from 'api/interfaces'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const audiencesTags = platformApi.enhanceEndpoints({ addTagTypes: ['DataAudiences', 'TVSegments', 'InternetSegments'] })

export const audiencesApi = audiencesTags.injectEndpoints({
  endpoints: build => {
    return {
      getDataAudiences: build.query<ThirdPartyAudience[], { query?: string }>({
        query: ({ query }) => ({
          url: `data/audiences/thirdParty?query=${query}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { query }) => [
          {
            type: 'DataAudiences',
            id: query
          }
        ]
      }),
      getTvSegments: build.query<SegmentTarget[], { query?: string }>({
        query: ({ query }) => ({
          url: `data/segments/tv?query=${query}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { query }) => [
          {
            type: 'TVSegments',
            id: query
          }
        ]
      }),
      getInternetSegments: build.query<SegmentTarget[], { query?: string }>({
        query: ({ query }) => ({
          url: `data/segments/internet?query=${query}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { query }) => [
          {
            type: 'InternetSegments',
            id: query
          }
        ]
      }),
      getBillboardGeos: build.query<BillboardGeo[], { lat: number, lng: number, radiusMeters: number}>({
        query: (request) => {
          return {
            url: `data/billboardsInRange`,
            method: "POST",
            data: {
              lat: request.lat,
              lng: request.lng,
              radiusMeters: request.radiusMeters,
            },
            isContext: true,
            isAgency: false
          };
        },
        transformResponse: defaultTransformFn,
        providesTags: () => [],
      }),
    }
  },
  overrideExisting: false
})

export const { useGetDataAudiencesQuery, useLazyGetDataAudiencesQuery, useGetTvSegmentsQuery, useGetInternetSegmentsQuery, useGetBillboardGeosQuery } = audiencesApi
