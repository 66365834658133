import { FC } from 'react'

import { EuiSpacer } from '@elastic/eui'

import { BaseTargeting, DeviceSettings, InventorySettings } from '../../../../../api/interfaces/TargetingTemplate'
import { CampaignDeliveryForm } from '../../../../../components/targeting/TargetingForm/CampaignDeliveryForm'
import { CampaignType } from '../../../../../api/interfaces'
import {
  InventoryAndDeviceSettingsForm
} from '../../../../../components/targeting/TargetingForm/InventoryAndDeviceSettingsForm'

export interface ICampaignDelivery {
  baseTargeting: BaseTargeting,
  inventorySettings?: InventorySettings,
  deviceSettings?: DeviceSettings
}

export interface IEditCampaignDeliveryStrategy {
  campaignType: CampaignType
  campaignDelivery: ICampaignDelivery
  onCampaignDeliveryChanged: (delivery: ICampaignDelivery) => void
}

export const EditCampaignDeliveryStrategy : FC<IEditCampaignDeliveryStrategy> = props => {
  const onBaseTargetingChanged = (targeting: BaseTargeting) => {
    props.onCampaignDeliveryChanged({...props.campaignDelivery, baseTargeting: targeting})
  }

  const onInventorySettingsChange = (inventorySettings: InventorySettings) => {
    props.onCampaignDeliveryChanged({...props.campaignDelivery, inventorySettings})
  }

  const onDeviceSettingsChange = (deviceSettings: DeviceSettings) => {
    props.onCampaignDeliveryChanged({...props.campaignDelivery, deviceSettings})
  }

  if (props.campaignType === CampaignType.Billboard)
  {
    return <></>
  }

  return (
    <>
      <CampaignDeliveryForm targeting={props.campaignDelivery.baseTargeting} onTargetingChanged={onBaseTargetingChanged} campaignType={props.campaignType} />
      <EuiSpacer />
      {props.campaignType === CampaignType.Internet && props.campaignDelivery.inventorySettings && props.campaignDelivery.deviceSettings && (
        <>
          <InventoryAndDeviceSettingsForm inventorySettings={props.campaignDelivery.inventorySettings} deviceSettings={props.campaignDelivery.deviceSettings} onInventorySettingsChange={onInventorySettingsChange} onDeviceSettingsChange={onDeviceSettingsChange} />
          <EuiSpacer/>
        </>
      )}
    </>
  )
}
