import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiCollapsibleNav, EuiSideNav, EuiHeaderSectionItemButton, EuiIcon } from '@elastic/eui'
import { EuiSideNavItemType } from '@elastic/eui/src/components/side_nav/side_nav_types'

import { PermissionType } from 'api/interfaces'
import { useGetCanSeeBundleInsightsQuery } from 'api/rtkQueryApi/platform/bundleInsightsApi'
import { RootState } from 'app/rootReducer'
import { initializeCampaignCreate } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { useGetClientPlansQuery } from '../../api/rtkQueryApi/platform/clientPlansApi'
import { useOrganizationPermissions } from '../../providers/OrganizationPermissionsContext'
import { useIsAgency } from '../../whiteLabel/useIsAgency'

export const AdCritterAiSideNav: React.FC = () => {
  const dispatch = useDispatch()
  const isAgency = useIsAgency()
  const { currentUser, currentOrganization, currentAccount, isVendastaUser, isShopifyUser } = useSelector((state: RootState) => state.app)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const whiteLabel = useWhiteLabel()
  const { hasPermission } = useOrganizationPermissions()
  const canUseTemplateBuilder = hasPermission(PermissionType.UseTemplateBuilderCampaignCreation)
  const clientPlansQuery = useGetClientPlansQuery(
    {
      organizationId: currentOrganization?.id ?? ''
    },
    { skip: !hasPermission(PermissionType.UseAdCritterAi) }
  )

  const { isFetching: isFetchingCanSeeBundleInsights, data: canSeeBundleInsights } = useGetCanSeeBundleInsightsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount || !currentOrganization })

  const getNavItems = (): EuiSideNavItemType<any>[] => {
    const items: EuiSideNavItemType<any>[] = []

    const dspItem: EuiSideNavItemType<any> = {
      id: 'adcritter-dsp',
      name: 'AdCritter DSP',
      icon: <EuiIcon type={'dashboardApp'} />,
      onClick: () => history.push('/dashboard'),
      items: []
    }

    hasPermission(PermissionType.UseAdCritterAi) &&
      items.push({
        id: 'adcritter-ai',
        name: 'AdCritter AI',
        icon: <EuiIcon type={'machineLearningApp'} />,
        disabled: !clientPlansQuery.data,
        onClick: () => {
          if (!clientPlansQuery.data) {
            return
          }
          clientPlansQuery.data?.length === 0 && history.push('/ai/welcome')
          clientPlansQuery.data.length > 0 && history.push('/ai')
        }
      })

    !isFetchingCanSeeBundleInsights &&
      canSeeBundleInsights &&
      dspItem.items?.push({
        id: 'dashboard',
        name: 'Dashboard',
        onClick: () => history.push('/dashboard')
      })

    dspItem.items?.push({
      id: 'campaigns',
      name: 'Campaigns',
      onClick: () => history.push('/campaignGroups')
    })

    !whiteLabel?.hideReports() &&
      dspItem.items?.push({
        id: 'reports',
        name: 'Reports',
        onClick: () => history.push('/reports')
      })

    !whiteLabel?.isVista() &&
      dspItem.items?.push({
        id: 'audience-pixel',
        name: 'Audience Pixel',
        onClick: () => history.push('/audiencePixel')
      })

    isAgency &&
      canUseTemplateBuilder &&
      dspItem.items?.push({
        id: 'templates',
        name: 'Templates',
        onClick: () => {
          dispatch(initializeCampaignCreate())
          CreateCampaignStorageManager.DeleteFromLocalStorage()
          history.push('/templateBuild')
        }
      })

    dspItem.items?.push({
      id: 'settings',
      name: 'Settings',
      onClick: () => history.push('/settings')
    })

    !isVendastaUser &&
      !isShopifyUser &&
      !whiteLabel?.hideKnowledgeBase() &&
      dspItem.items?.push({
        id: 'contact-support',
        name: 'Contact Support',
        onClick: () => {
          window.open(`https://adcritter.com/contact?${whiteLabel?.isAgencies() ? 'questionType=Agencies' : ''}`, '_blank')
        }
      })

    items.push(dspItem)
    return items
  }

  return (
    currentUser && (
      <EuiCollapsibleNav
        aria-label='Main navigation'
        isOpen={navIsOpen}
        isDocked={true}
        size={224}
        button={
          <EuiHeaderSectionItemButton id='menuToggle' aria-label='Toggle main navigation' onClick={() => setNavIsOpen(!navIsOpen)} style={{ marginTop: 4 }}>
            <EuiIcon type='menu' size='m' aria-hidden='true' color='#ffffff' />
          </EuiHeaderSectionItemButton>
        }
        onClose={() => setNavIsOpen(false)}>
        <EuiSideNav
          items={getNavItems()}
          style={{
            paddingTop: '12px',
            paddingLeft: '12px',
            width: '100%'
          }}
        />
      </EuiCollapsibleNav>
    )
  )
}
