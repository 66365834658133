import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiPortal, EuiSpacer, EuiTitle } from '@elastic/eui'

import { hideAccountsFlyout, showAddAccountFlyout } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import AccountsGrid from 'features/accounts/AccountsGrid'
import AddAccountFlyout from 'features/accounts/AddAccountFlyout'

const AccountsFlyout: FC = () => {
  const dispatch = useDispatch()
  const { accountsFlyoutOpen, addAccountFlyoutOpen } = useSelector((state: RootState) => state.app)

  const closeFlyout = () => dispatch(hideAccountsFlyout())

  return accountsFlyoutOpen ? (
    <EuiPortal>
      <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
        <EuiFlyoutHeader hasBorder>
          <EuiTitle>
            <h4>Clients</h4>
          </EuiTitle>

          <EuiSpacer size='m' />
          <EuiButtonEmpty iconType='plusInCircle' iconSide='left' onClick={() => dispatch(showAddAccountFlyout())}>
            Add Client
          </EuiButtonEmpty>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <AccountsGrid />
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty id='close' iconType='cross' onClick={closeFlyout} flush='left'>
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
      {addAccountFlyoutOpen && <AddAccountFlyout />}
    </EuiPortal>
  ) : null
}

export default AccountsFlyout
