import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { useGetAccountsQuery } from '../../../api/rtkQueryApi/platform/accountsApi'
import { RootState } from '../../../app/rootReducer'
import history from '../../../services/HistoryService'
import { ClientPlanContent } from '../ClientPlanContent'
import { useGetClientPlansQuery } from '../../../api/rtkQueryApi/platform/clientPlansApi'
import { ClientPlan } from '../../../api/interfaces/ClientPlan'

enum ClientPlanStatus {
  Building = 'Building',
  Completed = 'Completed'
}

interface ClientPlanItem {
  id: string
  name: string
  accountName: string
  created: Moment
  status: ClientPlanStatus
}

const StatusBadge = (status: ClientPlanStatus) => {
    return <EuiBadge color={status === ClientPlanStatus.Building ? 'warning' : 'success'}>{status}</EuiBadge>
}

export const ClientPlansPage = () => {
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const getClientPlansQuery = useGetClientPlansQuery({ organizationId: currentOrganization?.id ?? '' })
  const accountsQuery = useGetAccountsQuery({})
  const [clientPlanItems, setClientPlanItems] = useState<ClientPlanItem[]>([])
  const [isViewClientPlan, setIsViewClientPlan] = useState<boolean>(false)
  const [selectedClientPlan, setSelectedClientPlan] = useState<ClientPlanItem>()

  const calculateClientPlanStatus = (clientPlan: ClientPlan): ClientPlanStatus => {
    const resources = clientPlan?.resources;
    if (!resources) {
      return ClientPlanStatus.Building
    }

    if(resources.mediaPlan?.fileUrl && resources.creativeStrategy?.fileUrl && resources.marketingStrategy?.fileUrl && resources.marketAnalysisAndTargeting?.fileUrl) {
      return ClientPlanStatus.Completed
    }
    return ClientPlanStatus.Building
  }

  useEffect(() => {
    if (getClientPlansQuery.data && accountsQuery.data) {
      const clientPlanItems = getClientPlansQuery.data.map(clientPlan => {
        const account = accountsQuery.data!.find(account => account.id === clientPlan.accountId)
        return {
          id: clientPlan.id,
          name: clientPlan.name,
          accountName: account?.name ?? '',
          created: moment(clientPlan.created),
          status: calculateClientPlanStatus(clientPlan)
        }
      })
      setClientPlanItems(clientPlanItems)
    }
  }, [getClientPlansQuery.data, accountsQuery.data])

  const onClientPlanViewClick = (planItem: ClientPlanItem) => {
    setSelectedClientPlan(planItem)
    setIsViewClientPlan(true)
  }

  const onClientPlanViewCloseClick = () => {
    setIsViewClientPlan(false)
    setSelectedClientPlan(undefined)
  }

  const columns: EuiBasicTableColumn<ClientPlanItem>[] = [
    {
      name: 'Client',
      field: 'accountName',
      sortable: true
    },
    {
      name: 'Plan Name',
      sortable: (ap: ClientPlanItem) => ap.name,
      render: (ap: ClientPlanItem) => {
        return (
          <EuiLink onClick={() => onClientPlanViewClick(ap)} color='success'>
            {ap.name}
          </EuiLink>
        )
      }
    },
    {
      name: 'Date Created',
      field: 'created',
      render: (created: string) => {
        return new Date(created).toLocaleDateString()
      },
      sortable: true
    },
    {
      name: 'Status',
      field: "status",
      sortable: true,
      render: (status: ClientPlanStatus) => {
        return StatusBadge(status)
      }
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      field: 'created',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 20, 25, 0]
  }

  const search = {
    box: {
      incremental: true
    }
  }

  const onCreateClick = () => {
    history.push('/ai/create-plan')
  }

  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1> AdCritter AI</h1>
            </EuiTitle>
            <EuiText>Welcome to AdCritter AI!</EuiText>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiFlexGroup justifyContent={'spaceBetween'}>
            <EuiFlexItem>
              <EuiTitle size={'s'}>
                <h2>Client Plans</h2>
              </EuiTitle>
              <EuiText>Each client plan will include market analysis and targeting, marketing strategy and plan, timeline measurement and budget, a pitch deck, and a campaign group</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton fill onClick={onCreateClick}>
                Create
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiInMemoryTable search={search} className={'table-color'} items={clientPlanItems} loading={accountsQuery.isLoading || getClientPlansQuery.isLoading || accountsQuery.isFetching || getClientPlansQuery.isFetching} columns={columns} compressed pagination={pagination} sorting={sorting} />
        </EuiPanel>
        {isViewClientPlan && selectedClientPlan && (
          <EuiFlyout onClose={onClientPlanViewCloseClick} size={'s'}>
            <EuiFlyoutHeader>
              <>
                <EuiTitle>
                  <h2>{selectedClientPlan.accountName}</h2>
                </EuiTitle>
                <EuiText>
                  <p>{selectedClientPlan.name}</p>
                </EuiText>
              </>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
              <ClientPlanContent clientPlanId={selectedClientPlan.id} />
            </EuiFlyoutBody>
          </EuiFlyout>
        )}
      </EuiPageBody>
    </EuiPage>
  )
}
