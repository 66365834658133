import { Campaign, CampaignType, FrequencyUnit } from '../api/interfaces'
import { AdPlacementType, CampaignDelivery } from '../api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting, LocationTargetingType } from '../api/interfaces/CampaignTargeting'
import { Address, AgeRange, AudienceTargeting as AudienceTemplate, AudienceTargeting as AudienceTargetingTemplate, BaseTargeting, City, DayPartitioning, DeviceSettings, Dma, FrequencyCapSettings, FrequencyCapUnit, Gender, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, HouseholdIncome, InternetTargeting, InventorySettings, PostalCode, Region, Segment, TvTargeting } from '../api/interfaces/TargetingTemplate'
import { DefaultTargetingState } from '../features/builders/DefaultTargetingSlice'
import { BuilderCampaignType } from '../workflows/CampaignCreateWorkflow'
import { DEFAULT_AUDIENCE_TARGETING, DEFAULT_DAY_PARTS, DEFAULT_DEVICE_SETTINGS, DEFAULT_INTERNET_TARGETING, DEFAULT_INVENTORY_SETTINGS, DEFAULT_LOCATION_TARGETING, DEFAULT_TV_TARGETING } from './TargetingFormDefaults'

const convertFrequencyUnit = (unit?: FrequencyCapUnit): FrequencyUnit => {
  switch (unit) {
    case FrequencyCapUnit.Hourly:
      return FrequencyUnit.hour
    case FrequencyCapUnit.Daily:
      return FrequencyUnit.day
    case FrequencyCapUnit.Weekly:
      return FrequencyUnit.week
    default:
      return FrequencyUnit.week
  }
}

const convertFrequencyUnitToFrequencyCapUnit = (unit?: FrequencyUnit): FrequencyCapUnit => {
  switch (unit) {
    case FrequencyUnit.hour:
      return FrequencyCapUnit.Hourly
    case FrequencyUnit.day:
      return FrequencyCapUnit.Daily
    case FrequencyUnit.week:
      return FrequencyCapUnit.Weekly
    default:
      return FrequencyCapUnit.Weekly
  }
}

export const getCampaignDeliveryFromTemplate = ({ dayParts, frequencyCapSettings, inventorySettings, deviceSettings }: { dayParts: DayPartitioning; frequencyCapSettings: FrequencyCapSettings; inventorySettings: InventorySettings; deviceSettings: DeviceSettings }): CampaignDelivery => {
  return {
    dayParts,
    frequency: frequencyCapSettings.frequency ?? 12,
    frequencyUnit: convertFrequencyUnit(frequencyCapSettings.unit),
    inventorySettings: {
      targetApp: inventorySettings.targetApp ?? false,
      targetWeb: inventorySettings.targetWeb ?? true
    },
    deviceSettings: {
      targetPc: deviceSettings.targetPc ?? true,
      targetPhone: deviceSettings.targetPhone ?? true,
      targetTablet: deviceSettings.targetTablet ?? true
    },
    placement: AdPlacementType.Everywhere,
    categories: [],
    specific: []
  }
}

export const getAudienceTargetingFromTemplate = (audienceTemplate: AudienceTargetingTemplate, segments?: Segment[]): AudienceTargeting => {
  return {
    demographics: {
      ageRanges: audienceTemplate.ageRanges,
      genders: audienceTemplate.genders,
      householdIncomes: audienceTemplate.householdIncomes
    },
    segments: segments ?? []
  }
}

export const getLocationTargetingFromTemplate = ({ locationType, regions, dmas, cities, postalCodes, uploadedAudienceUrl, geoCircles, geoPolygons, geoRectangles, addresses }: { locationType: LocationTargetingType; regions: Region[]; dmas: Dma[]; cities: City[]; postalCodes: PostalCode[]; uploadedAudienceUrl?: string; geoFences?: GeoFence[]; geoCircles?: GeoCircle[]; geoPolygons?: GeoPolygon[]; geoRectangles?: GeoRectangle[]; addresses?: Address[] }): LocationTargeting => {
  return {
    targetingType: locationType,
    regions,
    dmas,
    cities,
    zips: postalCodes,
    addresses: addresses ?? [],
    geoCircles: geoCircles ?? [],
    geoRectangles: geoRectangles ?? [],
    geoPolygons: geoPolygons ?? [],
    uploadedAudienceUrl
  }
}

export const getAudienceTemplateByCampaignType = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): AudienceTemplate => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return defaultTargetingTemplate.tvTargeting.audienceTargeting ?? defaultTargetingTemplate.audienceTargeting
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return defaultTargetingTemplate.internetTargeting.audienceTargeting ?? defaultTargetingTemplate.audienceTargeting
    case BuilderCampaignType.Billboard:
      return defaultTargetingTemplate.billboardTargeting.audienceTargeting ?? defaultTargetingTemplate.audienceTargeting
    default:
      return DEFAULT_AUDIENCE_TARGETING
  }
}

export const getLocationTemplateByCampaignType = (
  defaultTargetingTemplate: DefaultTargetingState,
  campaignType: BuilderCampaignType
): {
  locationType: LocationTargetingType
  regions: Region[]
  dmas: Dma[]
  cities: City[]
  postalCodes: PostalCode[]
  uploadedAudienceUrl?: string
  geoCircles?: GeoCircle[]
  geoPolygons?: GeoPolygon[]
  geoRectangles?: GeoRectangle[]
  addresses?: Address[]
} => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return defaultTargetingTemplate.tvTargeting.locationTargeting ?? defaultTargetingTemplate.locationTargeting
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return defaultTargetingTemplate.internetTargeting.locationTargeting ?? defaultTargetingTemplate.locationTargeting
    case BuilderCampaignType.Billboard:
      return defaultTargetingTemplate.billboardTargeting.locationTargeting ?? defaultTargetingTemplate.locationTargeting
    default:
      return DEFAULT_LOCATION_TARGETING
  }
}

export const getCampaignDeliveryTemplateByCampaignType = (
  defaultTargetingTemplate: DefaultTargetingState,
  campaignType: BuilderCampaignType
): {
  dayParts: DayPartitioning
  frequencyCapSettings: FrequencyCapSettings
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
} => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return {
        dayParts: defaultTargetingTemplate.tvTargeting.dayParts ?? DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: defaultTargetingTemplate.tvTargeting.frequencyCapSettings?.frequency ?? 12,
          unit: defaultTargetingTemplate.tvTargeting.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
        },
        inventorySettings: DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: DEFAULT_DEVICE_SETTINGS
      }
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return {
        dayParts: defaultTargetingTemplate.internetTargeting.dayParts ?? DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: defaultTargetingTemplate.internetTargeting.frequencyCapSettings?.frequency ?? 12,
          unit: defaultTargetingTemplate.internetTargeting.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
        },
        inventorySettings: defaultTargetingTemplate.internetTargeting.advancedInternetTargeting?.inventorySettings ?? DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: defaultTargetingTemplate.internetTargeting.advancedInternetTargeting?.deviceSettings ?? DEFAULT_DEVICE_SETTINGS
      }
    case BuilderCampaignType.Billboard:
      return {
        dayParts: defaultTargetingTemplate.billboardTargeting.dayParts ?? DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: defaultTargetingTemplate.billboardTargeting.frequencyCapSettings?.frequency ?? 12,
          unit: defaultTargetingTemplate.billboardTargeting.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
        },
        inventorySettings: DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: DEFAULT_DEVICE_SETTINGS
      }
    default:
      return {
        dayParts: DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: 12,
          unit: FrequencyCapUnit.Weekly
        },
        inventorySettings: DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: DEFAULT_DEVICE_SETTINGS
      }
  }
}

export const getSegmentsFromTemplate = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): Segment[] => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return defaultTargetingTemplate.tvTargeting.segments ?? []
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return defaultTargetingTemplate.internetTargeting.segments ?? []
    default:
      return []
  }
}

export const GetLocationType = (locationTypeString: string | null): LocationTargetingType => {
  switch (locationTypeString) {
    case 'USA':
      return LocationTargetingType.USA
    case 'State':
      return LocationTargetingType.Region
    case 'DMA':
      return LocationTargetingType.DMA
    case 'City':
      return LocationTargetingType.City
    case 'Zip':
      return LocationTargetingType.Zip
    case 'Map':
      return LocationTargetingType.Map
    case 'Address':
      return LocationTargetingType.Address
    case 'Uploaded':
      return LocationTargetingType.Uploaded
    default:
      return LocationTargetingType.USA
  }
}

export const GetInternetTargetingTemplateFromCampaign = (campaign: Campaign): InternetTargeting => {
  const internetTargeting = campaign.advancedTargeting
  if (!internetTargeting) {
    return DEFAULT_INTERNET_TARGETING
  }
  return {
    dayParts: internetTargeting.dayParts ?? DEFAULT_DAY_PARTS,
    frequencyCapSettings: {
      frequency: internetTargeting.frequency ?? 14,
      unit: internetTargeting.frequencyUnit ? convertFrequencyUnitToFrequencyCapUnit(internetTargeting.frequencyUnit) : FrequencyCapUnit.Daily
    },
    segments: internetTargeting.segments,
    advancedInternetTargeting: {
      advancedTargetingType: internetTargeting.advancedTargetingType,
      audienceType: internetTargeting.audienceType ?? '',
      retargetingType: internetTargeting.retargetingType ?? '',
      inventorySettings: {
        targetApp: internetTargeting.inventorySettings?.targetApp ?? false,
        targetWeb: internetTargeting.inventorySettings?.targetWeb ?? true
      },
      deviceSettings: {
        targetPc: internetTargeting.deviceSettings?.targetPc ?? true,
        targetPhone: internetTargeting.deviceSettings?.targetPhone ?? true,
        targetTablet: internetTargeting.deviceSettings?.targetTablet ?? true
      }
    },
    locationTargeting: {
      locationType: GetLocationType(internetTargeting.locationType),
      regions: internetTargeting.locations?.regions ?? [],
      dmas: internetTargeting.locations?.dmas ?? [],
      cities: internetTargeting.locations?.cities ?? [],
      postalCodes: internetTargeting.locations?.postalCodes ?? [],
      uploadedAudienceUrl: internetTargeting.locations?.uploadedAudienceUrl,
      geoFences: internetTargeting.geoFences ?? [],
      geoCircles: internetTargeting.geoCircles,
      geoPolygons: internetTargeting.geoPolygons,
      geoRectangles: internetTargeting.geoRectangles,
      addresses: []
    },
    audienceTargeting: {
      ageRanges: (internetTargeting.ageRanges as AgeRange[]) ?? ['all'],
      genders: (internetTargeting.genders as Gender[]) ?? ['all'],
      householdIncomes: (internetTargeting.householdIncomes as HouseholdIncome[]) ?? ['all']
    }
  }
}

export const GetTvTargetingTemplateFromCampaign = (campaign: Campaign): TvTargeting => {
  const tvTargeting = campaign?.tvTargeting

  if (!tvTargeting) {
    return DEFAULT_TV_TARGETING
  }
  return {
    segments: tvTargeting.segments,
    frequencyCapSettings: {
      frequency: tvTargeting.frequency ?? 12,
      unit: tvTargeting.frequencyUnit ? convertFrequencyUnitToFrequencyCapUnit(tvTargeting.frequencyUnit) : FrequencyCapUnit.Weekly
    },
    dayParts: tvTargeting.dayParts ?? DEFAULT_DAY_PARTS,
    audienceTargeting: {
      ageRanges: (tvTargeting.ageRanges as AgeRange[]) ?? ['all'],
      genders: (tvTargeting.genders as Gender[]) ?? ['all'],
      householdIncomes: (tvTargeting.householdIncomes as HouseholdIncome[]) ?? ['all']
    },
    locationTargeting: {
      locationType: GetLocationType(tvTargeting.locationType),
      regions: tvTargeting.locations?.regions ?? [],
      dmas: tvTargeting.locations?.dmas ?? [],
      cities: tvTargeting.locations?.cities ?? [],
      postalCodes: tvTargeting.locations?.postalCodes ?? []
    }
  }
}

export const GetBillboardTargetingTemplateFromCampaign = (campaign: Campaign): BaseTargeting => {
  const billboardTargeting = campaign?.billboardTargeting

  if (!billboardTargeting) {
    return DEFAULT_TV_TARGETING
  }
  return {
    locationTargeting: {
      locationType: GetLocationType(billboardTargeting.locationType),
      regions: billboardTargeting.locations?.regions ?? [],
      dmas: billboardTargeting.locations?.dmas ?? [],
      cities: billboardTargeting.locations?.cities ?? [],
      postalCodes: billboardTargeting.locations?.postalCodes ?? []
    }
  }
}

export const GetTargetingFromCampaign = (campaign: Campaign): InternetTargeting | BaseTargeting | TvTargeting | null => {
  switch (campaign.type) {
    case CampaignType.Internet:
      return GetInternetTargetingTemplateFromCampaign(campaign)
    case CampaignType.Billboard:
      return GetBillboardTargetingTemplateFromCampaign(campaign)
    case CampaignType.TV:
      return GetTvTargetingTemplateFromCampaign(campaign)
    default:
      return null
  }
}
