import { GeoPoint } from '../../api/interfaces'

export interface GeoJsonPoints {
  type: string
  cluster: boolean
  clusterMaxZoom: number
  clusterRadius: number
  data: {
    type: string
    features: {
      type: string
      geometry: {
        type: string
        coordinates: [number, number]
      },
      properties: { title: string }
    }[]
  }
}

export const CreateGeoJsonPoints = (points: GeoPoint[]): GeoJsonPoints => {
  return {
    type: 'geojson',
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50,
    data: {
      type: 'FeatureCollection',
      features: points.map(point => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [point.lng, point.lat]
          },
          properties: { title: 'Point' }
        }
      })
    }
  }
}