import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { EuiInMemoryTable, EuiLink, EuiLoadingSpinner, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CampaignGroup } from '../../../api/interfaces/CampaignGroup'
import { useGetCampaignGroupsQuery } from '../../../api/rtkQueryApi/platform/campaignGroupsApi'
import { RootState } from '../../../app/rootReducer'
import history from '../../../services/HistoryService'

export const ListCampaignGroups = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const campaignGroupsQuery = useGetCampaignGroupsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount })
  const [currentPage, setCurrentPage] = useState<number>(0)
  const location = useLocation()

  const onGroupClicked = (campaignGroup: CampaignGroup) => {
    history.push(`${location.pathname}/${campaignGroup.id}`)
  }

  const columns: EuiBasicTableColumn<CampaignGroup>[] = [
    {
      name: 'Group',
      sortable: (campaignGroup: CampaignGroup) => campaignGroup.name,
      render: (campaignGroup: CampaignGroup) => (
        <EuiLink onClick={() => onGroupClicked(campaignGroup)} color={'success'}>
          {campaignGroup.name}
        </EuiLink>
      )
    },
    {
      name: 'Type',
      field: 'type',
      sortable: true
    },
    {
      name: 'Active Campaigns',
      field: 'activeCampaigns',
      sortable: true
    },
    {
      name: 'Saved Campaigns',
      field: 'savedCampaigns',
      sortable: true
    },
    {
      name: 'Ended Campaigns',
      field: 'endedCampaigns',
      sortable: true
    }
  ]

  const sorting = {
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100],
    pageIndex: currentPage,
    onChangePage: (pageIndex: number) => setCurrentPage(pageIndex)
  }

  const isLoading = campaignGroupsQuery.isLoading

  return <React.Fragment>{isLoading ? <EuiLoadingSpinner /> : <EuiInMemoryTable items={campaignGroupsQuery.data ?? []} columns={columns} pagination={pagination} sorting={sorting} />}</React.Fragment>
}
