import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFieldNumber, EuiFieldText, EuiFormRow, EuiSpacer, EuiSwitch } from '@elastic/eui'

import { useGetCampaignGroupQuery, useUpdateCampaignGroupMutation } from '../../../api/rtkQueryApi/platform/campaignGroupsApi'
import { RootState } from '../../../app/rootReducer'
import { IPaymentMethodPickerState, PaymentMethodPicker } from '../../../components/payments/PaymentMethodPicker'

export const CampaignGroupSettings = ({ campaignGroupId }: { campaignGroupId: string }) => {
  const { currentUser } = useSelector((state: RootState) => state.app)

  const [updateCampaignGroupMutation, updateCampaignGroupMutationResult] = useUpdateCampaignGroupMutation()
  const campaignGroup = useGetCampaignGroupQuery({ campaignGroupId }, { skip: !campaignGroupId })

  const [name, setName] = useState<string>(campaignGroup.data?.name ?? '')
  const [activateDaysBeforeStartDate, setActivateDaysBeforeStartDate] = useState(campaignGroup.data?.activateDaysBeforeStartDate)
  const [paymentMethodPickerState, setPaymentMethodPickerState] = useState<IPaymentMethodPickerState>({
    selectedPaymentMethodId: campaignGroup.data?.paymentMethodId ?? '',
    selectedCampaignSlotId: ''
  })
  const [autoActivate, setAutoActivate] = useState<boolean>(campaignGroup.data?.autoActivate ?? false)

  useEffect(() => {
    if (campaignGroup.data) {
      setActivateDaysBeforeStartDate(campaignGroup.data.activateDaysBeforeStartDate)
      setPaymentMethodPickerState({
        selectedPaymentMethodId: campaignGroup.data.paymentMethodId ?? '',
        selectedCampaignSlotId: ''
      })
      setAutoActivate(campaignGroup.data.autoActivate)
      setName(campaignGroup.data.name)
    }
  }, [campaignGroup.data])

  const onCampaignSettingsSave = async () => {
    if (!currentUser) {
      return
    }
    await updateCampaignGroupMutation({
      campaignGroupId,
      data: {
        name,
        autoActivate,
        paymentMethodId: paymentMethodPickerState.selectedPaymentMethodId,
        activateDaysBeforeStartDate: activateDaysBeforeStartDate,
        updatedBy: currentUser.email
      }
    })
  }
  return (
    <>
      <EuiFormRow label={'Campaign Group Name'}>
        <EuiFieldText placeholder='Placeholder text' value={name} onChange={e => setName(e.target.value)} />
      </EuiFormRow>
      <EuiFormRow label={'Activate Days Before Start Date'} helpText={'Number of days before the start date to activate the campaigns in this campaign group.'}>
        <EuiFieldNumber min={3} value={activateDaysBeforeStartDate} onChange={e => setActivateDaysBeforeStartDate(e.target.valueAsNumber)} />
      </EuiFormRow>
      <EuiFormRow label={'Auto Activate Campaigns'} helpText={'Automatically activate campaigns in this campaign group when the start date is reached.'}>
        <EuiSwitch
          label='Auto Activate'
          checked={autoActivate}
          onChange={e => {
            setAutoActivate(e.target.checked)
          }}
        />
      </EuiFormRow>
      <PaymentMethodPicker paymentMethodPickerState={paymentMethodPickerState} updatePaymentMethodPickerState={setPaymentMethodPickerState} />
      <EuiSpacer />
      <EuiButton fill onClick={onCampaignSettingsSave} isLoading={updateCampaignGroupMutationResult.isLoading}>
        Save
      </EuiButton>
    </>
  )
}
