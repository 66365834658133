import { EuiFormRow, EuiSpacer, EuiText } from '@elastic/eui'

export const ClientPlanConfirmCreateStep = () => {
  return (
    <>
      <EuiFormRow fullWidth>
        <EuiText>
          <p>AdCritter AI will now work on creating a first draft of a detailed proposal and media plan.</p><p> We will
          email you when it is complete, generally within 24 hours.</p>
        </EuiText>
      </EuiFormRow>
      <EuiSpacer />
    </>
  )
}
