import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiForm, EuiCheckableCard, EuiFormRow, EuiIcon, EuiListGroup, EuiListGroupItem, EuiLoadingContent, EuiSpacer, EuiText, EuiTitle, EuiToolTip, htmlIdGenerator } from '@elastic/eui'

import { ApiError, apiPutCampaignAdPlacement } from 'api/adcritterApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'

import { WhiteLabel } from '../../../../api/interfaces'
import { useWhiteLabel } from '../../../../whiteLabel/WhiteLabelContext'
import { fetchCampaign } from '../campaignSlice'

interface FormValues {
  placement: string
  categories: Array<string>
  specific: Array<string>
}

const AdPlacementDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    placement: 'Everywhere',
    categories: [],
    specific: []
  })
  const [newSite, setNewSite] = useState<string>('')
  const [invalidSite, setInvalidSite] = useState<boolean>(false)
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (campaign.campaign) {
      setFormValues({
        placement: campaign.campaign.placement,
        categories: campaign.campaign.categories,
        specific: campaign.campaign.specific
      })
    }
  }, [campaign.campaign])

  const detailSchema = Yup.object().shape({
    placement: Yup.string().required('Please choose where your ads will be placed'),
    categories: Yup.array().when(['placement'], {
      is: (placement: string) => placement === 'Categories',
      then: Yup.array().min(1, 'Please Select at Least 1 Category').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    specific: Yup.array().when(['placement'], {
      is: (placement: string) => placement === 'Specific',
      then: Yup.array().min(1, 'Please Add at Least 1 Site').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    })
  })

  const siteSchema = Yup.object().shape({
    site: Yup.string()
      .required()
      .matches(/^(?!-)(?:(?:[a-zA-Z\d][a-zA-Z\d-]{0,61})?[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/, 'Please Enter a Valid Site')
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: detailSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaignAdPlacement(currentAccount!.id, campaign.campaign!.id, values.placement, values.categories, values.specific)
        .then(() => {
          dispatch(showSuccessToast('Saved Ad Placement'))
          dispatch(fetchCampaign(currentAccount!.id, campaign.campaign!.id))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const updateCategories = (isChecked: boolean, category: string) => {
    if (isChecked && formik.values.categories.filter(c => c === category).length === 0) {
      const newCategories = [...formik.values.categories]
      newCategories.push(category)
      formik.setFieldValue('categories', newCategories, true)
    } else if (!isChecked && formik.values.categories.filter(c => c === category).length > 0) {
      const index = formik.values.categories.indexOf(category)
      const newCategories = [...formik.values.categories]
      newCategories.splice(index, 1)
      formik.setFieldValue('categories', newCategories, true)
    }
  }

  const addNewSite = () => {
    siteSchema.isValid({ site: newSite }).then(function (valid) {
      if (valid) {
        setInvalidSite(false)
        const sites = [...formik.values.specific]
        sites.push(newSite)
        formik.setFieldValue('specific', sites, true)
        setNewSite('')
      } else {
        setInvalidSite(true)
      }
    })
  }

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiTitle size='xs'>
          <h2>Ad Placement</h2>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiText size='s'>
          <p>Prioritize where your ads appear on the Internet.</p>
        </EuiText>
        <EuiSpacer />

        <EuiCheckableCard
          id='everywhere'
          name='placement'
          value='Everywhere'
          label={
            <React.Fragment>
              <strong>Prioritize highest performing sites. (Recommended)</strong>{' '}
              <EuiToolTip position='bottom' content='Prioritizes all top brand-safe sites and categories to deliver top results.'>
                <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
              </EuiToolTip>
            </React.Fragment>
          }
          checked={formik.values.placement === 'Everywhere'}
          onChange={() => {
            formik.setFieldValue('placement', 'Everywhere', true)
          }}>
          <EuiText size='s' color={formik.values.placement !== 'Everywhere' ? 'subdued' : 'primary'}>
            Prioritizes all top brand-safe sites and categories to deliver top results.
          </EuiText>
        </EuiCheckableCard>
        <EuiSpacer size='m' />
        <EuiCheckableCard
          id='categories'
          name='placement'
          value='Categories'
          disabled={true}
          label={<EuiText size='s'>Prioritize specific categories of sites.</EuiText>}
          checked={formik.values.placement === 'Categories'}
          onChange={() => {
            formik.setFieldValue('placement', 'Categories', true)
            if (formik.values.categories.length === 0) {
              formik.setFieldValue('categories', ['Business', 'Social', 'Portals', 'Lifestyle', 'Sports', 'Celebrity', 'News', 'Finance', 'Weather', 'Community', 'Entertainment'], true)
            }
          }}>
          <EuiSpacer size='s' />
          <div style={{ marginLeft: 35 }}>
            <EuiFlexGrid columns={3} gutterSize='s'>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Business')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Business').length > 0}
                  label={
                    <React.Fragment>
                      Business{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top business sites such as Wall Street Journal, CNN, Fox Business, MSN, Business Insider, Forbes and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Social')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Social').length > 0}
                  label={
                    <React.Fragment>
                      Social{' '}
                      <EuiToolTip position='bottom' content='Prioritizes social sites and user generated content platforms such as Reddit, Tumblr, specialty blogs and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Portals')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Portals').length > 0}
                  label={
                    <React.Fragment>
                      Portals{' '}
                      <EuiToolTip position='bottom' content='Prioritizes web portal sites and top homepages such as MSN, Yahoo, AOL, Daily Mail, Bing...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Lifestyle')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Lifestyle').length > 0}
                  label={
                    <React.Fragment>
                      Lifestyle{' '}
                      <EuiToolTip position='bottom' content='Prioritizes lifestyle sites such as inStyle, Travel &amp; Leisure, People, Mashable, POPSUGAR, USA Today and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Sports')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Sports').length > 0}
                  label={
                    <React.Fragment>
                      Sports{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top sports sites such as ESPN, Yahoo! Sports, CBS Sports, NFL, Rivals, Bleacher Report and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Celebrity')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Celebrity').length > 0}
                  label={
                    <React.Fragment>
                      Celebrity{' '}
                      <EuiToolTip position='bottom' content='Prioritizes premium sites featuring celebrity lifestyle content such as People, TMZ, E! News, Perez Hilton, too fab and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'News')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'News').length > 0}
                  label={
                    <React.Fragment>
                      News{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top news sites such as Newsweek, CBS, Huffington Post, Fox News, ABC News, local news outlets and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Finance')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Finance').length > 0}
                  label={
                    <React.Fragment>
                      Finance{' '}
                      <EuiToolTip position='bottom' content='Prioritizes financial sites such as Wall Street Journal, MarketWatch, Financial Times, Yahoo! Finance, Bloomberg, CNN Money and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Weather')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Weather').length > 0}
                  label={
                    <React.Fragment>
                      Weather{' '}
                      <EuiToolTip position='bottom' content='Prioritizes weather sites such as The Weather Channel, Accuweather, WeatherBug and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Community')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Community').length > 0}
                  label={
                    <React.Fragment>
                      Community{' '}
                      <EuiToolTip position='bottom' content='Prioritizes community sites such as NextDoor, Reddit, MSN, AOL, Yahoo!, specialty blogs and more....'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Entertainment')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Entertainment').length > 0}
                  label={
                    <React.Fragment>
                      Entertainment{' '}
                      <EuiToolTip position='bottom' content='Prioritizes entertainment sites such as Entertainment Weekly, Fandango, Thrillist, E! News, Variety, Rolling Stone and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </div>
        </EuiCheckableCard>
        <EuiSpacer size='s' />
        <EuiCheckableCard
          id='specific'
          name='placement'
          value='Specific'
          disabled={true}
          label={<EuiText size='s'>Prioritize a list of specific sites.</EuiText>}
          checked={formik.values.placement === 'Specific'}
          onChange={() => {
            formik.setFieldValue('placement', 'Specific', true)
          }}>
          <EuiText size='s' color={formik.values.placement !== 'Specific' ? 'subdued' : 'primary'}>
            Enter the sites you want to prioritize.
          </EuiText>
          <EuiSpacer size='m' />
          <div hidden={formik.values.placement !== 'Specific'} style={{ marginLeft: 35 }}>
            <EuiFormRow
              label='Site'
              isInvalid={invalidSite}
              error={'Please Enter a Valid Site'}
              labelAppend={
                <EuiText style={{ fontSize: '11px' }}>
                  <EuiToolTip position='bottom' content='Some example valid sites: mlb.com, huffpost.com, msnbc.com'>
                    <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                  </EuiToolTip>
                </EuiText>
              }>
              <EuiFieldText
                value={newSite}
                onChange={v => setNewSite(v.target.value)}
                isInvalid={invalidSite}
                append={
                  <EuiButton id='add' onClick={addNewSite}>
                    Add
                  </EuiButton>
                }
              />
            </EuiFormRow>
            <EuiSpacer size='s' />
            <EuiListGroup bordered={true} maxWidth={false} hidden={formik.values.specific.length === 0}>
              {formik.values.specific.map(s => (
                <EuiListGroupItem
                  key={s}
                  label={s}
                  extraAction={{
                    onClick: () => {
                      formik.setFieldValue(
                        'specific',
                        formik.values.specific.filter(x => x !== s)
                      )
                    },
                    iconType: 'cross',
                    iconSize: 's',
                    'aria-label': 'Remove',
                    alwaysShow: true
                  }}
                />
              ))}
            </EuiListGroup>
          </div>
        </EuiCheckableCard>
        <EuiSpacer />

        {/*<EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>*/}
        {/*  Save*/}
        {/*</EuiButton>*/}
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        {whiteLabel?.getWhiteLabel() !== WhiteLabel.AgencyV2 && (
          <EuiFlexItem grow={false} style={{ width: 260 }}>
            <EuiText size='xs'>
              <h3>Best Practices</h3>
              <h5>Running Campaigns</h5>
              <p>While campaigns can be edited anytime, it is generally a best practice to change things slowly and carefully. For example, making frequent changes to a campaign's targeting or budget settings can hinder the machine learning and optimization algorithms.</p>
            </EuiText>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default AdPlacementDrawer
