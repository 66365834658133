import { useSelector } from 'react-redux'

import { EuiSpacer, EuiTabbedContent } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { CampaignType } from '../../../../api/interfaces'
import { RootState } from '../../../../app/rootReducer'
import { RetargetingDrawer } from '../RetargetingDrawer'
import BillboardAdsDrawer from '../billboards/AdsDrawer'
import InternetAdsDrawer from '../internet/AdsDrawer'
import TvAdsDrawer from '../tv/AdsDrawer'
import { EditCampaignDetails } from './EditCampaignDetails'
import { EditAudienceTargeting } from './forms/EditAudienceTargeting'
import { EditBillboardLocationTargeting } from './forms/EditBillboardLocationTargeting'
import { EditInternetLocationTargeting } from './forms/EditInternetLocationTargeting'
import { EditTvLocationTargeting } from './forms/EditTvLocationTargeting'

export const CampaignTabs = () => {
  const { campaign } = useSelector((state: RootState) => state.campaign)

  if (!campaign) {
    return null
  }

  const tabs: Array<EuiTabbedContentTab> = [
    {
      id: 'details',
      name: 'Campaign Details',
      content: <EditCampaignDetails />
    },
    {
      id: 'ads',
      name: 'Campaign Ads',
      content: (
        <>
          {campaign.type === CampaignType.Billboard && <BillboardAdsDrawer />}
          {campaign.type === CampaignType.Internet && <InternetAdsDrawer />}
          {campaign.type === CampaignType.TV && <TvAdsDrawer />}
        </>
      )
    },
    {
      id: 'geoTargeting',
      name: 'Geo Targeting',
      content: (
        <>
          <EuiSpacer />
          {campaign.type === CampaignType.TV && <EditTvLocationTargeting />}
          {campaign.type === CampaignType.Internet && <EditInternetLocationTargeting />}
          {campaign.type === CampaignType.Billboard && <EditBillboardLocationTargeting />}
        </>
      )
    }
  ]

  if (campaign.type === CampaignType.Internet || campaign.type === CampaignType.TV)
    tabs.push({
      id: 'audienceTargeting',
      name: 'Audience Targeting',
      content: (
        <>
          <EuiSpacer />
          <EditAudienceTargeting />
        </>
      )
    })

  if (campaign.type === CampaignType.Internet) {
    tabs.push({
      id: 'retargeting',
      name: 'Retargeting',
      content: <RetargetingDrawer />
    })
  }

  return <EuiTabbedContent tabs={tabs} />
}
