import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel, EuiSpacer, EuiTab, EuiTabs,
  EuiTitle
} from '@elastic/eui'
import React from 'react'
import { BillboardInventoryExplorer } from './BillboardInventoryExplorer'
import { InternetInventoryExplorer } from './InternetInventoryExplorer'
import { TvInventoryExplorer } from './TvInventoryExplorer'

enum InventoryType {
  Billboard = 'Billboard',
  Internet = 'Internet',
  TV = 'TV'
}

export const InventoryExplorerPage: React.FC = () => {
  const [selectedInventoryType, setSelectedInventoryType] = React.useState<InventoryType>(InventoryType.Billboard);

  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>Inventory Explorer</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiTabs>
            <EuiTab isSelected={selectedInventoryType === InventoryType.Billboard} onClick={() => setSelectedInventoryType(InventoryType.Billboard)}>Billboard</EuiTab>
            <EuiTab isSelected={selectedInventoryType === InventoryType.Internet} onClick={() => setSelectedInventoryType(InventoryType.Internet)}>Internet</EuiTab>
            <EuiTab isSelected={selectedInventoryType === InventoryType.TV} onClick={() => setSelectedInventoryType(InventoryType.TV)}>TV</EuiTab>
          </EuiTabs>
          <EuiSpacer/>
          {selectedInventoryType === InventoryType.Billboard && <BillboardInventoryExplorer/>}
          {selectedInventoryType === InventoryType.Internet && <InternetInventoryExplorer/>}
          {selectedInventoryType === InventoryType.TV && <TvInventoryExplorer/>}
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  )
}