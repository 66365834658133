import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { apiGetDataAddressPlace } from 'api/adcritterApi'
import { AddressAutocomplete, GeoFence } from 'api/interfaces'
import { clearAddressAutocompletes, fetchAddressAutocompletes } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface GeoFenceTargeterProps {
  isInvalid: boolean

  onGeoFenceClicked(geoFence: GeoFence): void
}

export const GeoFenceFinder = ({ onGeoFenceClicked, isInvalid }: GeoFenceTargeterProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [addressSearchTerm, setAddressSearchTerm] = useState<string>('')
  const debouncedAddressSearchTerm = useDebounce(addressSearchTerm, 500)
  const [searchAddressPopoverOpen, setSearchAddressPopoverOpen] = useState<boolean>(false)
  const [addressAutocompletes, setAddressAutocompletes] = useState<Array<EuiSelectableOption>>([])

  const onSearchAddress = () => {
    setSearchAddressPopoverOpen(false)
  }

  const onSearchAddressChange = (hint: string) => {
    setAddressSearchTerm(hint)
  }

  const onSearchAddressClick = useCallback(
    (address: AddressAutocomplete) => {
      setSearchAddressPopoverOpen(false)
      setAddressSearchTerm('')
      apiGetDataAddressPlace(address.placeId).then(response => {
        onGeoFenceClicked({
          placeId: response.data.placeId,
          name: response.data.formattedAddress,
          lat: response.data.lat,
          lng: response.data.lng,
          radius: 100,
          radiusUnits: 'meters',
          formattedAddress: response.data.formattedAddress
        })
      })
    },
    [onGeoFenceClicked]
  )

  useEffect(() => {
    if (debouncedAddressSearchTerm) {
      dispatch(fetchAddressAutocompletes(debouncedAddressSearchTerm))
    } else {
      dispatch(clearAddressAutocompletes())
    }
  }, [debouncedAddressSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.addressAutocompletes) {
      setAddressAutocompletes(
        dictionary.addressAutocompletes.map(
          a =>
            ({
              label: a.description,
              key: a.placeId,
              checked: undefined,
              onClick: () => onSearchAddressClick(a)
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.addressAutocompletes, onSearchAddressClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={addressSearchTerm}
          isLoading={dictionary.isLoadingAddressAutocompletes}
          onChange={v => onSearchAddressChange(v.target.value)}
          onFocus={() => setSearchAddressPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchAddress()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchAddressPopoverOpen}
      closePopover={() => setSearchAddressPopoverOpen(false)}>
      {dictionary.addressAutocompletes && dictionary.addressAutocompletes.length ? (
        <EuiSelectable singleSelection options={addressAutocompletes}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
