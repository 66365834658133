import { useMapBox } from '../../mapBox/useMapBox'
import { useGetBillboardGeosQuery } from '../../../api/rtkQueryApi/platform/audiencesApi'
import React, { useEffect, useState } from 'react'
import { BillboardGeo, GeoPoint } from '../../../api/interfaces'
import { useDebounceEffect } from '../../../utils/useDebounceEffect'
import { EuiPanel } from '@elastic/eui'

export interface IBillboardFinderV2 {
  search?: string
}

export const BillboardFinderV2: React.FC<IBillboardFinderV2> = props => {
  const { map, center, setMarkers, flyTo, searchPlace, isReady } = useMapBox()
  const [billboards, setBillboards] = useState<BillboardGeo[]>([])
  const [searchCenter, setSearchCenter] = useState<GeoPoint>({ lng: -98.5795, lat: 39.8283 })
  const radiusSearch = 20000000
  const radiusDifToSearch = 16000000

  const getBillboardQuery = useGetBillboardGeosQuery({
    lat: searchCenter.lat,
    lng: searchCenter.lng,
    radiusMeters: radiusSearch
  })

  useDebounceEffect(1000, () => {
    if (center && calculateDistance(center, searchCenter) > radiusDifToSearch) {
      setSearchCenter(center)
    }
  }, [center, searchCenter])

  const calculateDistance = (pointA: GeoPoint, pointB: GeoPoint): number => {
    if (!pointA || !pointB) {
      return 0
    }
    const R = 6371e3
    const φ1 = pointA.lat * Math.PI / 180
    const φ2 = pointB.lat * Math.PI / 180
    const Δφ = (pointB.lat - pointA.lat) * Math.PI / 180
    const Δλ = (pointB.lng - pointA.lng) * Math.PI / 180

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
  }

  useDebounceEffect(400, () => {
    if(!props.search || !isReady)
    {
      return
    }
    searchPlace(props.search).then(response => {
      flyTo(response.features[0].geometry.coordinates, 11)
    })
  }, [props.search, isReady])

  useEffect(() => {
    if(!getBillboardQuery?.data || !isReady)
    {
      return
    }
    setBillboards(getBillboardQuery.data ?? [])
  }, [getBillboardQuery.data, isReady])

  useEffect(() => {
    if (!isReady) {
      return
    }
    const bbMarkers = billboards.map(billboard => ({ lat: billboard.latitude, lng: billboard.longitude }))
    setMarkers(bbMarkers)
  }, [billboards, setMarkers, isReady])

  return (
    <EuiPanel hasBorder={false} hasShadow={false} style={{ height: 400 }}>
      {map}
    </EuiPanel>
  )
}