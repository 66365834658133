import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiCallOut, EuiFormRow, EuiHideFor, EuiRadio, EuiShowFor, EuiSpacer, EuiText } from '@elastic/eui'

import { PermissionType } from 'api/interfaces'
import { LocationTargeting, LocationTargetingType } from 'api/interfaces/CampaignTargeting'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import { GeoFenceFinder } from 'components/finders'
import { NewCityFinder } from 'components/finders/NewCityFinder'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { AcTargetingMap } from 'components/targeters/AcTargetingMap'
import { GeoFenceTargeter } from 'components/targeters/GeoFenceTargeter'
import { NewCityTargeter } from 'components/targeters/NewCityTargeter'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import { ZipTargeter } from 'components/targeters/ZipTargeter'

export interface ILocationTargeter {
  campaignType: BuilderCampaignType
  targetingDetails: LocationTargeting
  setTargetingDetails: (details: LocationTargeting) => void
}
export const LocationTargeter: React.FC<ILocationTargeter> = props => {
  const { isGoogleLoaded } = useSelector((state: RootState) => state.app)
  const organizationQuery = useGetOrganizationQuery()
  const { targetingDetails, setTargetingDetails } = props
  const [isInvalidUpload, setIsInvalidUpload] = useState(false)

  const getTargetedPlaces = useCallback(() => {
    if (targetingDetails.targetingType === LocationTargetingType.Region) {
      return targetingDetails.regions.map(r => r.name)
    }
    if (targetingDetails.targetingType === LocationTargetingType.DMA) {
      return targetingDetails.dmas.map(d => d.name)
    }
    if (targetingDetails.targetingType === LocationTargetingType.City) {
      return targetingDetails.cities.map(c => c.name)
    }
    if (targetingDetails.targetingType === LocationTargetingType.Zip) {
      return targetingDetails.zips.map(z => z.name)
    }
    if (targetingDetails.targetingType === LocationTargetingType.Address) {
      return targetingDetails.geoFences?.map(g => g.formattedAddress)
    }
    return undefined
  }, [targetingDetails])

  return (
    <React.Fragment>
      {(props.campaignType === BuilderCampaignType.TV || props.campaignType === BuilderCampaignType.Billboard) && (
        <React.Fragment>
          <EuiRadio
            id='usa'
            name='locationType'
            value='USA'
            label={
              <EuiText size='s'>
                <strong>Target the USA</strong>
              </EuiText>
            }
            checked={targetingDetails?.targetingType === LocationTargetingType.USA}
            onChange={() => {
              setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.USA })
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Target by State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={targetingDetails?.targetingType === LocationTargetingType.Region}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.Region })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Target by Metro Area / DMA</strong> (one or more)
          </EuiText>
        }
        checked={targetingDetails?.targetingType === LocationTargetingType.DMA}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.DMA })
        }}
      />
      {props.campaignType !== BuilderCampaignType.Billboard && (
        <React.Fragment>
          <EuiSpacer size='s' />
          <EuiRadio
            id='city'
            name='locationType'
            value='City'
            label={
              <EuiText size='s'>
                <strong>Target by City</strong> (one or more)
              </EuiText>
            }
            checked={targetingDetails?.targetingType === LocationTargetingType.City}
            onChange={() => {
              setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.City })
            }}
          />
        </React.Fragment>
      )}
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Target by Zip Code</strong> (one or more)
          </EuiText>
        }
        checked={targetingDetails?.targetingType === LocationTargetingType.Zip}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.Zip })
        }}
      />
      {(props.campaignType === BuilderCampaignType.Native || props.campaignType === BuilderCampaignType.Display) && (
        <React.Fragment>
          <EuiSpacer size='s' />
          <EuiRadio
            id='address'
            name='locationType'
            value='Address'
            label={
              <EuiText size='s'>
                <strong>Target by Street Addresses</strong> (one or more)
              </EuiText>
            }
            checked={targetingDetails?.targetingType === LocationTargetingType.Address}
            onChange={() => {
              setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.Address })
            }}
          />

          <EuiSpacer size='s' />
          <EuiRadio
            id='map'
            name='locationType'
            value='Map'
            label={
              <EuiText size='s'>
                <strong>Target on Map</strong> (draw the areas you want to target on a map)
              </EuiText>
            }
            checked={targetingDetails?.targetingType === LocationTargetingType.Map}
            onChange={() => {
              setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.Map })
            }}
          />
          {organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && (
            <React.Fragment>
              <EuiSpacer size='s' />
              <EuiRadio
                id='upload'
                name='locationType'
                value='Upload'
                label={
                  <EuiText size='s'>
                    <strong>Upload Location Audience</strong>
                  </EuiText>
                }
                checked={targetingDetails?.targetingType === LocationTargetingType.Uploaded}
                onChange={() => {
                  setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.Uploaded })
                }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <EuiSpacer size='xl' />
      {targetingDetails?.targetingType === LocationTargetingType.Region && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color={targetingDetails.regions.length < 1 ? 'primary' : 'success'} title='Search and select one or more U.S. states below.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='stateFinder' label='Search for a state' helpText={targetingDetails.regions.length < 1 ? 'At least one State is required' : undefined} fullWidth>
            <NewRegionFinder
              addRegion={regionToAdd => {
                setTargetingDetails({ ...targetingDetails, regions: [...targetingDetails.regions.filter(r => r.id !== regionToAdd.id), regionToAdd] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.regions && targetingDetails.regions.length > 0 && (
            <EuiFormRow label='Your campaign will target these states' fullWidth>
              <NewRegionTargeter
                regions={targetingDetails.regions}
                onRegionRemoved={regionId => {
                  const updatedRegions = targetingDetails.regions.filter(x => x.id !== regionId)
                  setTargetingDetails({ ...targetingDetails, regions: updatedRegions })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}

      {targetingDetails?.targetingType === LocationTargetingType.DMA && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color={targetingDetails.dmas.length < 1 ? 'primary' : 'success'} title='Search and select one or more metro areas / DMAs below.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='dmaFinder' label='Search for a metro area' helpText={targetingDetails.dmas.length < 1 ? 'At least one area is required' : undefined} fullWidth>
            <NewDmaFinder
              addDma={dmaToAdd => {
                setTargetingDetails({ ...targetingDetails, dmas: [...targetingDetails.dmas.filter(d => d.id !== dmaToAdd.id), dmaToAdd] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.dmas && targetingDetails.dmas.length > 0 && (
            <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
              <NewDmaTargeter
                dmas={targetingDetails.dmas}
                onDmaRemoved={dmaId => {
                  const updatedDmas = targetingDetails.dmas.filter(x => x.id !== dmaId)
                  setTargetingDetails({ ...targetingDetails, dmas: updatedDmas })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}

      {targetingDetails?.targetingType === LocationTargetingType.City && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color={targetingDetails.cities.length < 1 ? 'primary' : 'success'} title='Search and select one or more U.S. cities by name.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='cityFinder' label='Search for a city' helpText={targetingDetails.cities.length < 1 ? 'At least one City is required' : undefined} fullWidth>
            <NewCityFinder
              onCityClicked={city => {
                setTargetingDetails({ ...targetingDetails, cities: [...targetingDetails.cities.filter(c => c.id !== city.id), city] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.cities && targetingDetails.cities.length > 0 && (
            <EuiFormRow label='Your campaign will target these cities' fullWidth>
              <NewCityTargeter
                cities={targetingDetails.cities}
                onCityRemoved={cityId => {
                  const updatedCities = targetingDetails.cities.filter(x => x.id !== cityId)
                  setTargetingDetails({ ...targetingDetails, cities: updatedCities })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}

      {targetingDetails?.targetingType === LocationTargetingType.Zip && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color={targetingDetails.zips.length < 1 ? 'primary' : 'success'} title='Search and select one or more U.S. zip codes.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='zipFinder' label='Search for a zip code' helpText={targetingDetails.zips.length < 1 ? 'At least one Zip Code is required' : undefined} fullWidth>
            <NewPostalCodeFinder
              onPostalCodeClicked={zip => {
                setTargetingDetails({ ...targetingDetails, zips: [...targetingDetails.zips.filter(z => z.id !== zip.id), zip] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.zips && targetingDetails.zips.length > 0 && (
            <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
              <ZipTargeter
                zips={targetingDetails.zips.map(z => ({ id: z.id.toString(), name: z.name }))}
                onZipRemoved={zip => {
                  setTargetingDetails({ ...targetingDetails, zips: targetingDetails.zips.filter(z => z.id.toString() !== zip.id) })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}

      {targetingDetails?.targetingType === LocationTargetingType.Address && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color={(targetingDetails.geoFences ?? []).length < 1 ? 'primary' : 'success'} title='Search and select one or more U.S. street addresses.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='addressFinder' label='Search for an address' helpText={(targetingDetails.geoFences ?? []).length < 1 ? 'At least one Address is required' : undefined} fullWidth>
            <GeoFenceFinder
              onGeoFenceClicked={g => {
                const updatedGeoFences = [
                  ...(targetingDetails?.geoFences ?? []),
                  {
                    placeId: g.placeId,
                    radiusUnits: g.radiusUnits,
                    formattedAddress: g.name,
                    radius: g.radius,
                    geocodedAddress: g.name,
                    lat: g.lat,
                    lng: g.lng
                  }
                ]
                setTargetingDetails({ ...targetingDetails, geoFences: updatedGeoFences })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.geoFences && targetingDetails.geoFences.length > 0 && (
            <EuiFormRow label='Your campaign will target these addresses' fullWidth>
              <GeoFenceTargeter
                geoFence={(targetingDetails.geoFences ?? []).map(g => ({
                  placeId: g.placeId,
                  name: g.formattedAddress,
                  lat: g.lat,
                  lng: g.lng,
                  radius: g.radius,
                  radiusUnits: g.radiusUnits,
                  formattedAddress: g.formattedAddress
                }))}
                onGeoFenceRemoved={geoFence => {
                  const updatedGeoFences = targetingDetails.geoFences?.filter(g => g.placeId !== geoFence.placeId) ?? []
                  setTargetingDetails({ ...targetingDetails, geoFences: updatedGeoFences })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}

      {targetingDetails?.targetingType === LocationTargetingType.Map && (
        <React.Fragment>
          <EuiShowFor sizes={['xs', 's']}>
            <EuiCallOut color='warning' title='Drawing on the map is best experienced on a computer.' iconType='alert' size='s' />
          </EuiShowFor>

          <EuiHideFor sizes={['xs', 's']}>
            <EuiCallOut size='s' iconType='mapMarker' color='success' title='Use the box, circle and/or polygon tool to draw areas on the map you want to target.' />
          </EuiHideFor>
        </React.Fragment>
      )}

      <EuiSpacer size='s' />
      {(props.campaignType === BuilderCampaignType.Billboard || targetingDetails?.targetingType === LocationTargetingType.Map) && isGoogleLoaded && (
        <AcTargetingMap includeBillboards={props.campaignType === BuilderCampaignType.Billboard} locationTargeting={targetingDetails} onUpdatedLocationTargeting={t => setTargetingDetails(t)} hideDrawingTools={targetingDetails?.targetingType !== LocationTargetingType.Map} targetedPlaces={props.campaignType === BuilderCampaignType.Billboard ? getTargetedPlaces() : undefined} />
      )}
      {organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && targetingDetails?.targetingType === LocationTargetingType.Uploaded && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='filebeatApp' color='success' title='Target your own list' />
          <EuiSpacer size='s' />
          <EuiFormRow fullWidth helpText='Please only upload plain text files, with one record per line.'>
            <AcFileUploader
              maxFileSize={10000000}
              allowedFileTypes={['text/plain', 'text/csv']}
              onUploadedUrlChange={url => {
                setTargetingDetails({ ...targetingDetails, uploadedAudienceUrl: url })
              }}
              onIsValidFileChange={isValid => {
                setIsInvalidUpload(!isValid)
              }}
            />
          </EuiFormRow>
          {isInvalidUpload && <EuiCallOut title='Please upload a csv under 10MB' iconType='document' color='warning' />}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
