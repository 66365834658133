import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiModal, EuiModalBody, EuiModalHeader, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiProgress, EuiSpacer, EuiTitle } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

import { CampaignActiveStatus } from '../../../../api/interfaces'
import { useGetCampaignQuery } from '../../../../api/rtkQueryApi/platform/campaignsApi'
import { useGetCreativesQuery } from '../../../../api/rtkQueryApi/platform/creativesApi'
import { ActivateCampaignContent } from '../ActivateCampaignContent'
import { finishedLoadingAds, setCampaign as setGlobalCampaign } from '../campaignSlice'
import { CampaignTabs } from './CampaignTabs'

export const NewEditCampaignPage = () => {
  const dispatch = useDispatch()
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [showActivateCampaignModal, setShowActivateCampaignModal] = React.useState(false)
  let { campaignId } = useParams<{ campaignId: string }>()
  const campaignQuery = useGetCampaignQuery(
    { campaignId, accountId: currentAccount?.id ?? '' },
    {
      skip: !currentAccount || !!campaign
    }
  )

  const ads = useGetCreativesQuery(
    {
      campaignId,
      accountId: currentAccount?.id ?? '',
      isAgency: true
    },
    {
      skip: !currentAccount
    }
  )

  useEffect(() => {
    if (!!campaignQuery.data && !campaign) {
      dispatch(setGlobalCampaign(campaignQuery.data))
    }
  }, [campaign, campaignQuery.data, dispatch])

  useEffect(() => {
    if (!!ads.data) {
      dispatch(finishedLoadingAds(ads.data))
    }
  }, [ads.data, dispatch])

  const onActivateClick = () => {
    setShowActivateCampaignModal(true)
  }

  if (!campaign) {
    return <EuiProgress color={'warning'} />
  }

  return (
    <>
      <Helmet>
        <h1>Edit Campaign</h1>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Edit Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiFlexGroup justifyContent={'spaceBetween'} gutterSize={'m'} alignItems={'center'}>
          <EuiFlexItem grow={false}>
            <EuiTitle size='s'>
              <h2>{campaign?.name}</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiBadge color={campaign?.activeStatus === CampaignActiveStatus.Active ? 'success' : 'default'}>{campaign?.activeStatus}</EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem style={{ alignItems: 'flex-end' }}>
            <span>
              <EuiBadge>{campaign?.type} Campaign</EuiBadge>
            </span>
          </EuiFlexItem>
        </EuiFlexGroup>
        {campaign?.activeStatus === CampaignActiveStatus.Inactive && (
          <>
            <EuiSpacer />
            <EuiButton onClick={onActivateClick} fill>
              Activate
            </EuiButton>
            <EuiSpacer />
          </>
        )}
        <CampaignTabs />
      </EuiPanel>
      {showActivateCampaignModal && campaign && (
        <EuiModal onClose={() => setShowActivateCampaignModal(false)}>
          <EuiModalHeader>
            <EuiTitle>
              <h2>Activate Campaign</h2>
            </EuiTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <ActivateCampaignContent campaignId={campaign.id} amount={campaign.budget} campaignType={campaign.type} onActivated={() => setShowActivateCampaignModal(false)} />
          </EuiModalBody>
        </EuiModal>
      )}
    </>
  )
}
