import { useEffect, useState } from 'react'

import { EuiButtonIcon, EuiCallOut, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText } from '@elastic/eui'

import { City, Dma, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, InternetLocationTargeting, PostalCode } from 'api/interfaces/TargetingTemplate'

import { LocationTargetingType, TargetingType } from '../../../api/interfaces/CampaignTargeting'
import { AcTargeterSelection } from '../finders/AcTargeterSelection'
import { AddressFinder } from '../finders/AddressFinder'
import { CityFinderXandr } from '../finders/CityFinderXandr'
import { DMAFinderXandr } from '../finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from '../finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from '../finders/RegionFinderXandr'
import TargetingMap from './TargetingMap'

export interface ILocationTargetingForm {
  targetingType: TargetingType
  locationTargeting: InternetLocationTargeting
  onLocationTargetingChange: (locationTargeting: InternetLocationTargeting) => void
}

export const InternetLocationTargetingForm: React.FC<ILocationTargetingForm> = ({ targetingType, locationTargeting, onLocationTargetingChange }) => {
  const [zipsAsCsv, setZipsAsCsv] = useState('')

  useEffect(() => {
    if (locationTargeting.postalCodes) {
      setZipsAsCsv(locationTargeting.postalCodes?.map(r => r.code).join(','))
    }
  }, [locationTargeting.postalCodes])

  useEffect(() => {
    if (locationTargeting.locationType === LocationTargetingType.USA && targetingType === TargetingType.Location) {
      onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Map })
    }
  }, [locationTargeting.locationType, targetingType, locationTargeting, onLocationTargetingChange])

  return (
    <>
      {targetingType === TargetingType.Audience && (
        <>
          <EuiRadio
            id='us'
            name='locationType'
            value='USA'
            label={
              <EuiText size='s'>
                <strong>Targeting Entire USA</strong>
              </EuiText>
            }
            checked={locationTargeting.locationType === LocationTargetingType.USA}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.USA })
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Targeting Using State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.Region}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Region })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.DMA}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.DMA })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>Targeting Using City</strong> (one or more City names)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.City}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.City })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='address'
        name='locationType'
        value='Address'
        label={
          <EuiText size='s'>
            <strong>Target by Street Addresses</strong> (one or more)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.Address}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Address })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.Zip}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Zip })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='map'
        name='locationType'
        value='Map'
        label={
          <EuiText size='s'>
            <strong>Target on Map</strong> (draw the areas you want to target on a map)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Map'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Map })
        }}
      />
      <EuiSpacer size='s' />
      {locationTargeting.locationType === LocationTargetingType.Region && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({ ...locationTargeting, regions: [...(locationTargeting.regions ?? []), region] })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.DMA && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({ ...locationTargeting, dmas: [...(locationTargeting.dmas ?? []), dma] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.City && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({ ...locationTargeting, cities: [...(locationTargeting.cities ?? []), city] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Address && (
        <>
          <EuiFormRow fullWidth label={'Addresses'}>
            <AddressFinder
              onOptionClick={(geoFence: GeoFence) =>
                onLocationTargetingChange({
                  ...locationTargeting,
                  geoFences: [...(locationTargeting.geoFences ?? []), geoFence]
                })
              }
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.geoFences?.map(r => ({ key: r.placeId.toString(), label: `${r.formattedAddress}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, geoFences: locationTargeting.geoFences?.filter(r => r.placeId.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Zip && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={(zip: PostalCode) => onLocationTargetingChange({ ...locationTargeting, postalCodes: [...(locationTargeting.postalCodes ?? []), zip] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={zipsAsCsv}>{copy => <EuiButtonIcon title={'Copy as CSV'} iconType={'copy'} onClick={copy} />}</EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Map && (
        <>
          <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
          <EuiSpacer size='s' />
          {locationTargeting.locationType === 'Map' && (
            <TargetingMap
              geoCircles={locationTargeting.geoCircles ?? []}
              addCircle={(circle: GeoCircle) => {
                onLocationTargetingChange({ ...locationTargeting, geoCircles: [...(locationTargeting.geoCircles ?? []), circle] })
              }}
              removeCircle={(id: string) => {
                onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.filter(c => c.id !== id) ?? [] })
              }}
              modifyCircle={(circle: GeoCircle) => {
                onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.map(c => (c.id === circle.id ? circle : c)) })
              }}
              geoRectangles={locationTargeting.geoRectangles ?? []}
              addRectangle={(rectangle: GeoRectangle) => {
                onLocationTargetingChange({ ...locationTargeting, geoRectangles: [...(locationTargeting.geoRectangles ?? []), rectangle] })
              }}
              removeRectangle={(id: string) => {
                onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.filter(r => r.id !== id) ?? [] })
              }}
              modifyRectangle={(rectangle: GeoRectangle) => {
                onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.map(r => (r.id === rectangle.id ? rectangle : r)) })
              }}
              geoPolygons={locationTargeting.geoPolygons ?? []}
              addPolygon={(polygon: GeoPolygon) => {
                onLocationTargetingChange({ ...locationTargeting, geoPolygons: [...(locationTargeting.geoPolygons ?? []), polygon] })
              }}
              removePolygon={(id: string) => {
                onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.filter(p => p.id !== id) ?? [] })
              }}
              modifyPolygon={(polygon: GeoPolygon) => {
                onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.map(p => (p.id === polygon.id ? polygon : p)) ?? [] })
              }}
            />
          )}
        </>
      )}
    </>
  )
}
