import React, { useEffect, useState } from 'react'

import { EuiButton, EuiFormRow, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner } from '@elastic/eui'

import { apiDownloadPdf } from '../../api/adcritterApi'
import { ClientPlan } from '../../api/interfaces/ClientPlan'
import { useGetClientPlanQuery } from '../../api/rtkQueryApi/platform/clientPlansApi'
import { AcBadgeText } from '../../components/Basic/AcBadgeText'
import history from '../../services/HistoryService'

interface IClientPlanContent {
  clientPlanId: string
}

export const ClientPlanContent: React.FC<IClientPlanContent> = props => {
  const { clientPlanId } = props
  const [clientPlan, setClientPlan] = useState<ClientPlan>()
  const clientPlanGetQuery = useGetClientPlanQuery({ clientPlanId: clientPlanId })

  const downloadPdf = (clientPlanName: string, url: string, fileName: string) => {
    apiDownloadPdf(url).then(data => {
      const blobUrl = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', `${clientPlanName}_${fileName}`)
      link.click()
      link.remove()
      URL.revokeObjectURL(blobUrl)
    })
  }

  useEffect(() => {
    if (clientPlanGetQuery.data) {
      setClientPlan(clientPlanGetQuery.data)
    }
  }, [clientPlanGetQuery.data])

  const onMarketAnalysisAndTargetClicked = async () => {
    if (!clientPlan?.resources?.marketAnalysisAndTargeting?.fileUrl) {
      return
    }

    const url = clientPlan.resources.marketAnalysisAndTargeting.fileUrl

    downloadPdf(clientPlan.name, url, 'MarketAnalysisAndTargeting.pdf')
  }

  const onMarketingStrategyClicked = () => {
    if (!clientPlan?.resources?.marketingStrategy?.fileUrl) {
      return
    }
    const url = clientPlan.resources.marketingStrategy.fileUrl

    downloadPdf(clientPlan.name, url, 'MarketingStrategy.pdf')
  }

  const onMediaPlanClicked = () => {
    if (!clientPlan?.resources?.mediaPlan?.fileUrl) {
      return
    }
    const url = clientPlan.resources.mediaPlan.fileUrl
    downloadPdf(clientPlan.name, url, 'MediaPlan.pdf')
  }

  const onCreativeStrategyClicked = () => {
    if (!clientPlan?.resources?.creativeStrategy?.fileUrl) {
      return
    }
    const url = clientPlan.resources.creativeStrategy.fileUrl
    downloadPdf(clientPlan.name, url, 'CreativeStrategy.pdf')
  }

  const onViewCampaignGroupClicked = () => {
    if (clientPlan?.campaignGroupId) {
      history.push(`/campaignGroups/${clientPlan.campaignGroupId}`)
    }
  }

  return (
    <React.Fragment>
      {!clientPlan?.campaignGroupId && clientPlanGetQuery.isLoading ? (
        <EuiLoadingSpinner size='xl' />
      ) : (
        <React.Fragment>
          <EuiFormRow label={'Resources'}>
            <EuiListGroup>
              <EuiListGroupItem wrapText onClick={onMarketAnalysisAndTargetClicked} disabled={!clientPlan?.resources?.marketAnalysisAndTargeting?.fileUrl} iconType={'document'} label={<AcBadgeText text={'Market Analysis and Targeting'} badgeText={clientPlan?.resources?.marketAnalysisAndTargeting?.fileUrl ? 'Complete' : 'Creating'} badgeColor={clientPlan?.resources?.marketAnalysisAndTargeting?.fileUrl ? 'success' : 'warning'} />} />
              <EuiListGroupItem wrapText onClick={onMarketingStrategyClicked} disabled={!clientPlan?.resources?.marketingStrategy?.fileUrl} iconType={'training'} label={<AcBadgeText text={'Marketing Strategy'} badgeText={clientPlan?.resources?.marketingStrategy?.fileUrl ? 'Complete' : 'Creating'} badgeColor={clientPlan?.resources?.marketingStrategy?.fileUrl ? 'success' : 'warning'} />} />
              <EuiListGroupItem wrapText onClick={onCreativeStrategyClicked} disabled={!clientPlan?.resources?.creativeStrategy?.fileUrl} iconType={'cloudSunny'} label={<AcBadgeText text={'Creative Strategy'} badgeText={clientPlan?.resources?.creativeStrategy?.fileUrl ? 'Complete' : 'Creating'} badgeColor={clientPlan?.resources?.creativeStrategy?.fileUrl ? 'success' : 'warning'} />} />
              <EuiListGroupItem wrapText onClick={onMediaPlanClicked} disabled={!clientPlan?.resources?.mediaPlan?.fileUrl} iconType={'visArea'} label={<AcBadgeText text={'Media Plan'} badgeText={clientPlan?.resources?.mediaPlan?.fileUrl ? 'Complete' : 'Creating'} badgeColor={clientPlan?.resources?.mediaPlan?.fileUrl ? 'success' : 'warning'} />} />
              <EuiListGroupItem wrapText onClick={onViewCampaignGroupClicked} disabled={!clientPlan?.campaignGroupId} iconType={'inspect'} label='View Campaign Group' />
            </EuiListGroup>
          </EuiFormRow>
          <EuiFormRow>
            <EuiButton disabled>AI Editor</EuiButton>
          </EuiFormRow>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
