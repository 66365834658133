import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiCollapsibleNav, EuiCollapsibleNavGroup, EuiFlexGroup, EuiFlexItem, EuiHeaderSectionItemButton, EuiHorizontalRule, EuiIcon, EuiLink, EuiText, EuiTitle } from '@elastic/eui'

import { PermissionType } from 'api/interfaces'
import { useGetCanSeeBundleInsightsQuery } from 'api/rtkQueryApi/platform/bundleInsightsApi'
import { RootState } from 'app/rootReducer'
import { initializeCampaignCreate } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { useOrganizationPermissions } from '../../providers/OrganizationPermissionsContext'
import { useIsAgency } from '../../whiteLabel/useIsAgency'
import { SideNavBlurb } from './SideNavBlurb'
import { SideNavItem } from './SideNavItem'

export enum ViewContent {
  User = 'user',
  Agency = 'agency'
}

export const AdCritterSideNav: React.FC = () => {
  const dispatch = useDispatch()
  const isAgency = useIsAgency()
  const { campaigns } = useSelector((state: RootState) => state.campaigns)
  const { currentUser, currentOrganization, currentAccount, isVendastaUser, isShopifyUser } = useSelector((state: RootState) => state.app)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [showStartBlurb, setShowStartBlurb] = useState(false)
  const [showCreateTvBlurb, setShowCreateTvBlurb] = useState(false)
  const [showCreateInternetBlurb, setShowCreateInternetBlurb] = useState(false)
  const [showCreateBillboardBlurb, setShowCreateBillboardBlurb] = useState(false)
  const whiteLabel = useWhiteLabel()
  const { hasPermission } = useOrganizationPermissions()
  const canUseTemplates = hasPermission(PermissionType.UseTemplates)

  const { isFetching: isFetchingCanSeeBundleInsights, data: canSeeBundleInsights } = useGetCanSeeBundleInsightsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount || !currentOrganization })

  useEffect(() => {
    if (whiteLabel?.hideBlueNav()) {
      setShowStartBlurb(false)
      setShowCreateBillboardBlurb(false)
      setShowCreateInternetBlurb(false)
      setShowCreateTvBlurb(false)

      return
    }

    if (!campaigns || campaigns.length === 0 || (currentAccount && currentAccount.id === 'fccad035-e99a-4c08-864b-d67985e9d9d3')) {
      setShowStartBlurb(true)
      setShowCreateBillboardBlurb(false)
      setShowCreateInternetBlurb(false)
      setShowCreateTvBlurb(false)
    } else {
      let internet = campaigns.filter(c => c.type === 'Internet').length
      let tv = campaigns.filter(c => c.type === 'TV').length
      let billboard = campaigns.filter(c => c.type === 'Billboard').length

      if ((internet > 0 || billboard > 0) && tv === 0) {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(false)
        setShowCreateInternetBlurb(false)
        setShowCreateTvBlurb(true)
      } else if ((tv > 0 || billboard > 0) && internet === 0) {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(false)
        setShowCreateInternetBlurb(true)
        setShowCreateTvBlurb(false)
      } else if ((tv > 0 || internet > 0) && billboard === 0) {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(true)
        setShowCreateInternetBlurb(false)
        setShowCreateTvBlurb(false)
      } else {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(false)
        setShowCreateInternetBlurb(false)
        setShowCreateTvBlurb(false)
      }
    }
  }, [dispatch, campaigns, whiteLabel, currentAccount])

  return (
    currentUser && (
      <EuiCollapsibleNav
        aria-label='Main navigation'
        isOpen={navIsOpen}
        isDocked={true}
        size={224}
        button={
          <EuiHeaderSectionItemButton id='menuToggle' aria-label='Toggle main navigation' onClick={() => setNavIsOpen(!navIsOpen)} style={{ marginTop: 4 }}>
            <EuiIcon type='menu' size='m' aria-hidden='true' color='#ffffff' />
          </EuiHeaderSectionItemButton>
        }
        onClose={() => setNavIsOpen(false)}>
        {!isFetchingCanSeeBundleInsights && canSeeBundleInsights && <SideNavItem title={'Insights'} iconType={'lensApp'} onClick={() => history.push('/planInsights')} />}
        {!isFetchingCanSeeBundleInsights && !canSeeBundleInsights && <SideNavItem title={'Dashboard'} iconType={'dashboardApp'} onClick={() => history.push('/dashboard')} />}
        {!isVendastaUser && showStartBlurb && (
          <SideNavBlurb
            title={'Start Here'}
            text={`The best way to explore ${whiteLabel?.getName()} is to start setting up your first campaign, It's incredibly easy.`}
            linkText={'Create Campaign'}
            iconType={'globe'}
            onClick={() => {
              dispatch(initializeCampaignCreate())
              CreateCampaignStorageManager.DeleteFromLocalStorage()
              history.push('/build')
            }}
          />
        )}
        {showCreateInternetBlurb && (
          <SideNavBlurb
            title={'Internet Ads'}
            text={'Every TV Campaign should be supported with targeted internet ads. They serve as inexpensive reminders.'}
            linkText={'Create Campaign'}
            iconType={'globe'}
            onClick={() => {
              dispatch(initializeCampaignCreate())
              CreateCampaignStorageManager.DeleteFromLocalStorage()
              history.push('/build')
            }}
          />
        )}
        {showCreateTvBlurb && (
          <SideNavBlurb
            title={'TV Commercials'}
            text={'Thanks to streaming platforms, TV commercials can now be targeted as accurately as internet ads.'}
            linkText={'Create Campaign'}
            iconType={'playFilled'}
            onClick={() => {
              dispatch(initializeCampaignCreate())
              CreateCampaignStorageManager.DeleteFromLocalStorage()
              history.push('/build')
            }}
          />
        )}
        {showCreateBillboardBlurb && (
          <SideNavBlurb
            title={'Digital Billboards'}
            text={"Reinforce your campaigns with roadside billboards. It's the most cost effective brand builder there is."}
            linkText={'Create Campaign'}
            iconType={'fullScreen'}
            onClick={() => {
              dispatch(initializeCampaignCreate())
              CreateCampaignStorageManager.DeleteFromLocalStorage()
              history.push('/build')
            }}
          />
        )}
        {!showCreateTvBlurb && !showCreateBillboardBlurb && !showCreateInternetBlurb && <EuiHorizontalRule margin='none' />}
        <SideNavItem title={'Campaigns'} iconType={'savedObjectsApp'} onClick={() => history.push('/campaigns')} />
        {isAgency && <SideNavItem title={'Planner'} iconType={'machineLearningApp'} onClick={() => history.push('/planner')} />}
        {isAgency && canUseTemplates && (
          <SideNavItem
            title={'Templates'}
            iconType={'canvasApp'}
            onClick={() => {
              dispatch(initializeCampaignCreate())
              CreateCampaignStorageManager.DeleteFromLocalStorage()
              history.push('/build')
            }}
          />
        )}
        {!whiteLabel?.hideReports() && <SideNavItem title={'Reports'} iconType={'reportingApp'} onClick={() => history.push('/reports')} />}
        {!whiteLabel?.isVista() && <SideNavItem title={'Audience Pixel'} iconType={'graphApp'} onClick={() => history.push('/audiencePixel')} />}
        <SideNavItem title={'Settings'} iconType={'managementApp'} onClick={() => history.push('/settings')} />
        {!isVendastaUser && !isShopifyUser && !whiteLabel?.hideKnowledgeBase() && (
          <SideNavItem
            title={'Contact Support'}
            iconType={'notebookApp'}
            onClick={() => {
              window.open(`https://adcritter.com/contact?${whiteLabel?.isAgencies() ? 'questionType=Agencies' : ''}`, '_blank')
            }}
          />
        )}
      </EuiCollapsibleNav>
    )
  )
}
