import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ClientPlanCreateStep {
  PickClient = 'PickClient',
  SetUrl = 'SetUrl',
  SetBudget = 'SetBudget',
  AddAdditionalDetails = 'AddAdditionalDetails',
  Confirmation = 'Confirmation'
}

export interface CreateClientPlanState {
  step: ClientPlanCreateStep
  accountId: string
  accountName: string
  budget: string
  url: string
  details: string
}

const initialState: CreateClientPlanState = {
  step: ClientPlanCreateStep.PickClient,
  accountName: '',
  accountId: '',
  budget: '1000',
  url: '',
  details: ''
}

const createClientPlan = createSlice({
  name: 'createClientPlan',
  initialState,
  reducers: {
    setStep(state: CreateClientPlanState, { payload }: PayloadAction<ClientPlanCreateStep>) {
      state.step = payload
    },
    setAccountName(state: CreateClientPlanState, { payload }: PayloadAction<string>) {
      state.accountName = payload
    },
    setAccountId(state: CreateClientPlanState, { payload }: PayloadAction<string>) {
      state.accountId = payload
    },
    setBudget(state: CreateClientPlanState, { payload }: PayloadAction<string>) {
      state.budget = payload
    },
    setUrl(state: CreateClientPlanState, { payload }: PayloadAction<string>) {
      state.url = payload
    },
    setDetails(state: CreateClientPlanState, { payload }: PayloadAction<string>) {
      state.details = payload
    },
    reset(state: CreateClientPlanState) {
      state.step = initialState.step
      state.accountName = initialState.accountName
      state.accountId = initialState.accountId
      state.budget = initialState.budget
      state.url = initialState.url
      state.details = initialState.details
    }
  }
})

export const { setStep, setAccountName, setAccountId, setBudget, setDetails, setUrl, reset } = createClientPlan.actions

export default createClientPlan.reducer
