import { EuiFieldText, EuiForm, EuiFormRow, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { isValidURL } from '../../utils/isValidUrl'

type EnterUrlStepProps = {
  url: string
  clientName: string
  setUrl: (url: string) => void
}

export const EnterUrlStep = ({ url, setUrl, clientName }: EnterUrlStepProps) => {
  return (
    <>
      <EuiTitle size={'s'}>
        <h2>Confirm Website</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiForm>
        <EuiFormRow isInvalid={!isValidURL(url)} error={<EuiText size={'xs'}>Ensure URL begins with https://</EuiText>} label={`Enter website for ${clientName}`} helpText={'Enter a valid URL in the format: https://adcritter.com. If client has multiple sites, use the website this campaign plan should be based on.'}>
          <EuiFieldText value={url} onChange={e => setUrl(e.target.value)} />
        </EuiFormRow>
      </EuiForm>
    </>
  )
}
