import React from 'react'

import { EuiCollapsibleNavGroup, EuiLink, EuiText } from '@elastic/eui'
import { IconType } from '@elastic/eui/src/components/icon/icon'

type SideNavBlurbProps = {
  title: string
  text: string
  linkText: string
  iconType: IconType
  onClick: () => void
}

export const SideNavBlurb = ({ title, iconType, onClick, text, linkText }: SideNavBlurbProps) => {
  return (
    <EuiCollapsibleNavGroup background='dark' iconType={iconType} title={title} isCollapsible={true} initialIsOpen={true} arrowDisplay='none'>
      <React.Fragment>
        <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }}>
          <p>{text}</p>
        </EuiText>
        <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }} textAlign='right'>
          <EuiLink onClick={onClick} style={{ color: '#f49342' }}>
            {linkText}
          </EuiLink>
        </EuiText>
      </React.Fragment>
    </EuiCollapsibleNavGroup>
  )
}
