import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFormRow } from '@elastic/eui'

import { CampaignType } from '../../../../../api/interfaces'
import { LocationTargetingType } from '../../../../../api/interfaces/CampaignTargeting'
import { BaseTargeting, TvTargeting } from '../../../../../api/interfaces/TargetingTemplate'
import { RootState } from '../../../../../app/rootReducer'
import { LocationTargetingForm } from '../../../../../components/targeting/TargetingForm/LocationTargetingForm'
import { DEFAULT_LOCATION_TARGETING } from '../../../../../utils/TargetingFormDefaults'
import { GetBillboardTargetingTemplateFromCampaign, GetTvTargetingTemplateFromCampaign } from '../../../../../utils/TransformTargeting'
import { useUpdateCampaignTargeting } from './useUpdateCampaignTargeting'

export const EditBillboardLocationTargeting = () => {
  const { campaign } = useSelector((state: RootState) => state.campaign)

  const getTargeting = (): BaseTargeting | TvTargeting | null => {
    switch (campaign?.type) {
      case CampaignType.Billboard:
        return GetBillboardTargetingTemplateFromCampaign(campaign)
      case CampaignType.TV:
        return GetTvTargetingTemplateFromCampaign(campaign)
      default:
        return null
    }
  }

  const targeting = campaign && getTargeting()
  const [locationTargeting, setLocationTargeting] = useState(targeting?.locationTargeting)
  const [selectedLocationType, setSelectedLocationType] = useState<LocationTargetingType>(targeting?.locationTargeting?.locationType ?? LocationTargetingType.Region)

  const { updateCampaignTargeting, importBillboardTargetingMutationResult, importTvTargetingMutationResult } = useUpdateCampaignTargeting()

  const onSave = async () => {
    if (!campaign) {
      return
    }
    await updateCampaignTargeting(campaign, {
      ...targeting,
      locationTargeting: locationTargeting
    })
  }

  return (
    <>
      <EuiFormRow label={'Choose Location Targeting Strategy'} fullWidth>
        <LocationTargetingForm selectedLocationType={selectedLocationType} onSelectedLocationTypeChange={setSelectedLocationType} locationTargeting={locationTargeting ?? DEFAULT_LOCATION_TARGETING} onLocationTargetingChange={setLocationTargeting} useDefault />
      </EuiFormRow>
      <EuiButton onClick={onSave} isLoading={importTvTargetingMutationResult.isLoading || importBillboardTargetingMutationResult.isLoading}>
        Save
      </EuiButton>
    </>
  )
}
