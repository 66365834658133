import { EuiCheckbox, EuiFlexGrid, EuiFlexItem, EuiFormRow } from '@elastic/eui'
import { FC } from 'react'

import { DeviceSettings, InventorySettings } from '../../../api/interfaces/TargetingTemplate'

type IInventoryAndDeviceSettingsForm = {
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  onInventorySettingsChange: (inventorySettings: InventorySettings) => void
  onDeviceSettingsChange: (deviceSettings: DeviceSettings) => void
}

export const InventoryAndDeviceSettingsForm : FC<IInventoryAndDeviceSettingsForm> = props => {
  const {inventorySettings, deviceSettings} = props
  const onInventorySettingsChange = (inventorySettings: InventorySettings) => {
    props.onInventorySettingsChange(inventorySettings)
  }

  const onDeviceSettingsChange = (deviceSettings: DeviceSettings) => {
    props.onDeviceSettingsChange(deviceSettings)
  }

  return (
    <EuiFormRow label={'Inventory and Device Settings'}>
      <EuiFlexGrid columns={2}>
        <EuiFlexItem>
          <EuiCheckbox
            id={'targetApp'}
            onChange={() =>
              onInventorySettingsChange({
                ...inventorySettings,
                targetApp: !inventorySettings?.targetApp
              })
            }
            checked={inventorySettings?.targetApp}
            label={'Target App'}
          />
          <EuiCheckbox
            id={'targetWeb'}
            onChange={() =>
              onInventorySettingsChange({
                ...inventorySettings,
                targetWeb: !inventorySettings?.targetWeb
              })
            }
            checked={inventorySettings?.targetWeb}
            label={'Target Web'}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCheckbox
            id={'pc'}
            onChange={() =>
              onDeviceSettingsChange({
                ...deviceSettings,
                targetPc: !deviceSettings?.targetPc
              })
            }
            checked={deviceSettings?.targetPc}
            label={'Target PC'}
          />
          <EuiCheckbox
            id={'phone'}
            onChange={() =>
              onDeviceSettingsChange({
                ...deviceSettings,
                targetPhone: !deviceSettings?.targetPhone
              })
            }
            checked={deviceSettings?.targetPhone}
            label={'Target Phone'}
          />
          <EuiCheckbox
            id={'tablet'}
            onChange={() =>
              onDeviceSettingsChange({
                ...deviceSettings,
                targetTablet: !deviceSettings?.targetTablet
              })
            }
            checked={deviceSettings?.targetTablet}
            label={'Target Tablet'}
          />
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiFormRow>
  )
}
