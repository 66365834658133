import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiIcon, EuiLink, EuiTitle } from '@elastic/eui'
import { IconType } from '@elastic/eui/src/components/icon/icon'

type SideNavItemProps = {
  title: string
  iconType: IconType
  onClick: () => void
}

export const SideNavItem = ({ title, iconType, onClick }: SideNavItemProps) => {
  return (
    <>
      <div className='euiCollapsibleNavGroup__heading'>
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiIcon type={iconType} size='l' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size='xxs'>
              <EuiLink onClick={onClick} style={{ color: '#1a1c21' }}>
                <h3 className='euiCollapsibleNavGroup__title' id='campaignsNavItem'>
                  {title}
                </h3>
              </EuiLink>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <EuiHorizontalRule margin='none' />
    </>
  )
}
