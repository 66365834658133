import { ClientPlan } from '../../interfaces/ClientPlan'
import { defaultTransformFn, platformApi } from './platformApi'

const clientPlansTags = platformApi.enhanceEndpoints({ addTagTypes: ['ClientPlan', 'AccountClientPlans', 'OrganizationClientPlans'] })

type CreateClientPlanRequest = {
  organizationId: string
  params: {
    accountId: string
    businessName: string
    website: string
    budget: string
    details?: string
  }
}

export const clientPlansApi = clientPlansTags.injectEndpoints({
  endpoints: build => ({
    beginCreateClientPlan: build.mutation<ClientPlan, CreateClientPlanRequest>({
      query: ({ params }) => ({
        url: `clientPlans`,
        method: 'POST',
        data: {
          ...params
        },
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, _error, request) => [
        { type: 'ClientPlan', id: result?.id },
        { type: 'AccountClientPlans', id: request.params.accountId },
        { type: 'OrganizationClientPlans', id: request.organizationId }
      ]
    }),
    getClientPlan: build.query<ClientPlan, { clientPlanId: string }>({
      query: ({ clientPlanId }) => ({
        url: `clientPlans/${clientPlanId}`,
        method: 'GET',
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'ClientPlan', id: request.clientPlanId }]
    }),
    getClientPlansByAccountId: build.query<ClientPlan[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: `clientPlans`,
        method: 'GET',
        params: {
          accountId
        },
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'AccountClientPlans', id: request.accountId }]
    }),
    getClientPlans: build.query<ClientPlan[], { organizationId: string }>({
      query: () => ({
        url: `clientPlans`,
        method: 'GET',
        isContext: true,
        isAgency: false
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'OrganizationClientPlans', id: request.organizationId }]
    })
  })
})

export const { useBeginCreateClientPlanMutation, useGetClientPlanQuery, useGetClientPlansByAccountIdQuery, useGetClientPlansQuery } = clientPlansApi
