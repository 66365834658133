import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiTitle } from '@elastic/eui'

import { useGetCampaignsQuery } from '../../../api/rtkQueryApi/platform/campaignsApi'
import { RootState } from '../../../app/rootReducer'
import { useWhiteLabel } from '../../../whiteLabel/WhiteLabelContext'
import CampaignsList from './CampaignsList'
import { CreateCampaign } from './CreateCampaign'

const ListCampaignsPage: React.FC = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()

  const campaignsQuery = useGetCampaignsQuery({ accountId: currentAccount?.id ?? '', isAgency: whiteLabel?.isAgencies() }, { skip: !currentAccount?.id })

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Campaigns</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false}>
        <CampaignsList campaigns={campaignsQuery.data ?? []} isLoading={campaignsQuery.isLoading} />
        <CreateCampaign />
      </EuiPanel>
    </React.Fragment>
  )
}

export default ListCampaignsPage
