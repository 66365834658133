import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiTab, EuiTabs, EuiTitle } from '@elastic/eui'

import { useGetCampaignsQuery } from '../../../api/rtkQueryApi/platform/campaignsApi'
import { RootState } from '../../../app/rootReducer'
import { useWhiteLabel } from '../../../whiteLabel/WhiteLabelContext'
import CampaignsList from '../campaigns/CampaignsList'
import { CreateCampaign } from '../campaigns/CreateCampaign'
import { ListCampaignGroups } from './ListCampaignGroups'

enum CampaignTabType {
  CampaignGroups = 'campaignGroups',
  Campaigns = 'campaigns'
}

export const NewCampaignsPage: React.FC = () => {
  const [selectedTabType, setSelectedTabType] = React.useState<CampaignTabType>(CampaignTabType.Campaigns)

  const { currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()

  const campaignsQuery = useGetCampaignsQuery({ accountId: currentAccount?.id ?? '', isAgency: whiteLabel?.isAgencies() }, { skip: !currentAccount?.id })

  const onTabClick = (type: CampaignTabType) => {
    setSelectedTabType(type)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaign Groups</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Campaigns</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false}>
        <EuiTabs>
          <EuiTab onClick={() => onTabClick(CampaignTabType.Campaigns)} isSelected={selectedTabType === CampaignTabType.Campaigns}>
            All Campaigns
          </EuiTab>
          <EuiTab onClick={() => onTabClick(CampaignTabType.CampaignGroups)} isSelected={selectedTabType === CampaignTabType.CampaignGroups}>
            Campaign Groups
          </EuiTab>
        </EuiTabs>
        <EuiSpacer />
        {selectedTabType === CampaignTabType.CampaignGroups && <ListCampaignGroups />}
        {selectedTabType === CampaignTabType.Campaigns && (
          <>
            <CampaignsList campaigns={campaignsQuery.data ?? []} isLoading={campaignsQuery.isLoading} />
            <EuiSpacer />
            <CreateCampaign />
          </>
        )}
      </EuiPanel>
    </React.Fragment>
  )
}
