import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiTitle
} from '@elastic/eui'
import { useParams } from 'react-router-dom'
import React from 'react'
import { ClientPlanContent } from '../ClientPlanContent'

interface ClientPlanPageParams {
  clientPlanId: string
}

export const ClientPlanPage = () => {
  let { clientPlanId } = useParams<ClientPlanPageParams>()

  return (
    <EuiPage className="euiPage--platform">
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>AdCritter AI</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className="eui-xScroll">
          <ClientPlanContent clientPlanId={clientPlanId}/>
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  )
}
