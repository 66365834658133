import React from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { WhiteLabel } from '../../../api/interfaces'
import history from '../../../services/HistoryService'
import { useWhiteLabel } from '../../../whiteLabel/WhiteLabelContext'
import { initializeCampaignCreate } from '../../builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from '../../builders/createCampaign/CreateCampaignStorageManager'

export const CreateCampaign = () => {
  const whiteLabel = useWhiteLabel()
  const dispatch = useDispatch()

  const onCreateCampaignClick = () => {
    dispatch(initializeCampaignCreate())
    CreateCampaignStorageManager.DeleteFromLocalStorage()
    history.push('/build')
  }

  return (
    <EuiFlexGroup justifyContent='spaceAround' alignItems='flexStart'>
      <EuiFlexItem grow={false}>
        <EuiEmptyPrompt
          title={<h3>Let's start a new campaign.</h3>}
          titleSize='xs'
          body={whiteLabel?.getWhiteLabel() === WhiteLabel.AgencyV2 ? ' ' : 'Create as many campaigns as you desire with specific targeting to grow your business!'}
          actions={
            <EuiButton id='createCampaignBtn' size='s' key='createCampaign' onClick={onCreateCampaignClick} fill>
              Create Campaign
            </EuiButton>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
