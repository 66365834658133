import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import { EuiPage, EuiPageBody } from '@elastic/eui'

import PrivateRoute from '../../../components/PrivateRoute'
import { CampaignGroupPage } from './CampaignGroupPage'
import { NewCampaignsPage } from './NewCampaignsPage'

const CampaignGroupRoutes: React.FC = () => {
  const { path } = useRouteMatch()
  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <Switch>
          <PrivateRoute exact path={path}>
            <NewCampaignsPage />
          </PrivateRoute>
          <PrivateRoute path={`${path}/:campaignGroupId`}>
            <CampaignGroupPage />
          </PrivateRoute>
        </Switch>
      </EuiPageBody>
    </EuiPage>
  )
}

export default CampaignGroupRoutes
