import { EuiFormRow, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

type AdditionalDetailsStepProps = {
  details: string
  setDetails: (details: string) => void
}

export const AdditionalDetailsStep = ({ details, setDetails }: AdditionalDetailsStepProps) => {
  return (
    <>
      <EuiTitle size={'s'}>
        <h2>One Last Step...</h2>
      </EuiTitle>
      <EuiText><p>Please provide any additional insights about the client that might help AdCritter AI in building its first draft of the campaign plan.</p></EuiText>
      <EuiSpacer />
      <EuiFormRow>
        <EuiTextArea aria-label='Use aria labels when no actual label is in use' value={details} onChange={e => setDetails(e.target.value)} />
      </EuiFormRow>
    </>
  )
}
