import { EuiFieldSearch, EuiFormRow, EuiSpacer, EuiTitle, htmlIdGenerator } from '@elastic/eui'
import React from 'react'
import { BillboardFinderV2 } from '../../components/targeting/finders/BillboardFinderV2'

export const BillboardInventoryExplorer: React.FC = () => {
  const [cityName, setCityName] = React.useState<string>('');

  return (
    <>
      <EuiTitle size={'s'}>
        <h2>Explore Billboard Locations</h2>
      </EuiTitle>
      <EuiSpacer/>
      <EuiFormRow label={'Search City'}>
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={cityName}
          onChange={v => setCityName(v.target.value)}
          incremental={false}
          placeholder='Type city...'
        />
      </EuiFormRow>
      <EuiSpacer/>
      <BillboardFinderV2 search={cityName}/>
      <EuiSpacer/>
    </>
  )
}