import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { EuiLink, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiProgress, EuiSpacer, EuiTab, EuiTabs, EuiTitle } from '@elastic/eui'

import { useGetCampaignGroupCampaignsQuery, useGetCampaignGroupQuery } from '../../../api/rtkQueryApi/platform/campaignGroupsApi'
import { useGetClientPlanQuery } from '../../../api/rtkQueryApi/platform/clientPlansApi'
import history from '../../../services/HistoryService'
import CampaignsList from '../campaigns/CampaignsList'
import { CampaignGroupSettings } from './CampaignGroupSettings'

export interface CampaignGroupPagePageParams {
  campaignGroupId: string
}

enum CampaignGroupTabs {
  settings = 'settings',
  campaigns = 'campaigns'
}

export const CampaignGroupPage = () => {
  let { campaignGroupId } = useParams<CampaignGroupPagePageParams>()
  const [selectedTabType, setSelectedTabType] = useState<CampaignGroupTabs>(CampaignGroupTabs.campaigns)

  const onTabClick = (type: CampaignGroupTabs) => {
    setSelectedTabType(type)
  }

  const campaignGroupQuery = useGetCampaignGroupQuery({ campaignGroupId }, { skip: !campaignGroupId })

  const campaignsQuery = useGetCampaignGroupCampaignsQuery({ campaignGroupId }, { skip: !campaignGroupId })

  const clientPlanGetQuery = useGetClientPlanQuery({ clientPlanId: campaignGroupQuery.data?.clientPlanId ?? '' }, { skip: !campaignGroupQuery.data?.clientPlanId })

  const onClientPlanClick = () => {
    history.push(`/ai`)
  }

  if (!campaignGroupQuery.data) {
    return <EuiProgress color={'warning'} />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{campaignGroupQuery.data.name}</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>{campaignGroupQuery.data.name}</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false}>
        {clientPlanGetQuery.data && (
          <>
            <EuiTitle size={'xs'}>
              <h2>
                Client Plan:{' '}
                <EuiLink color={'success'} onClick={onClientPlanClick}>
                  {clientPlanGetQuery.data.name}
                </EuiLink>
              </h2>
            </EuiTitle>
            <EuiSpacer />
          </>
        )}
        <EuiTabs>
          <EuiTab onClick={() => onTabClick(CampaignGroupTabs.campaigns)} isSelected={selectedTabType === CampaignGroupTabs.campaigns}>
            Campaigns
          </EuiTab>
          <EuiTab onClick={() => onTabClick(CampaignGroupTabs.settings)} isSelected={selectedTabType === CampaignGroupTabs.settings}>
            Campaign Group Settings
          </EuiTab>
        </EuiTabs>
        <EuiSpacer />
        {selectedTabType === CampaignGroupTabs.campaigns && <CampaignsList campaigns={campaignsQuery.data ?? []} isLoading={campaignsQuery.isLoading} />}
        {selectedTabType === CampaignGroupTabs.settings && <CampaignGroupSettings campaignGroupId={campaignGroupId} />}
      </EuiPanel>
    </React.Fragment>
  )
}
