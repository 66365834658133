import { EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect } from '@elastic/eui'

import { CampaignType } from '../../../api/interfaces'
import { BaseTargeting, DayPartitioning, FrequencyCapUnit } from '../../../api/interfaces/TargetingTemplate'
import { DayPartPicker } from './DayPartPicker'

type CampaignDeliveryFormProps = {
  targeting: BaseTargeting
  onTargetingChanged: (campaignDelivery: BaseTargeting) => void
  campaignType: CampaignType
}

export const CampaignDeliveryForm = ({ targeting, onTargetingChanged, campaignType }: CampaignDeliveryFormProps) => {
  const options: {
    value: FrequencyCapUnit
    text: string
  }[] = [
    { value: FrequencyCapUnit.Hourly, text: 'Hourly' },
    { value: FrequencyCapUnit.Daily, text: 'Daily' },
    { value: FrequencyCapUnit.Weekly, text: 'Weekly' }
  ]

  const onFrequencyChange = (frequency: number) => {
    onTargetingChanged({
      ...targeting,
      frequencyCapSettings: {
        frequency,
        unit: targeting.frequencyCapSettings?.unit
      }
    })
  }

  const onFrequencyCapUnitChange = (frequencyCapUnit: FrequencyCapUnit) => {
    onTargetingChanged({
      ...targeting,
      frequencyCapSettings: {
        frequency: targeting.frequencyCapSettings?.frequency,
        unit: frequencyCapUnit
      }
    })
  }

  const onDayPartsChange = (dayParts: DayPartitioning) => {
    onTargetingChanged({
      ...targeting,
      dayParts
    })
  }

  // TODO: add support for inventory and device settings

  return (
    <>
      <EuiFlexGroup direction='column'>
        <EuiFlexItem>
          {campaignType !== CampaignType.Billboard && (
            <EuiFormRow label={'Frequency'}>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiFieldNumber
                    fullWidth
                    value={targeting.frequencyCapSettings?.frequency}
                    step={1}
                    onChange={e => {
                      onFrequencyChange(Number(e.target.value))
                    }}
                    min={0}
                    append={'views / user'}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiSelect options={options} value={targeting.frequencyCapSettings?.unit} hasNoInitialSelection={!targeting.frequencyCapSettings?.frequency} onChange={e => onFrequencyCapUnitChange(e.target.value as FrequencyCapUnit)} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Day Partiting' fullWidth>
            <DayPartPicker onSelectedDayPartsChange={onDayPartsChange} dayParts={targeting.dayParts} />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
