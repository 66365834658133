import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiCard, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiLink, EuiPortal, EuiSelect, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { Account, BusinessType, WhiteLabel } from 'api/interfaces'
import { useCreateAccountMutation } from 'api/rtkQueryApi/platform/accountsApi'
import { CreateAccountRequest } from 'api/rtkQueryApi/requestModels/AccountModels'
import { hideAddAccountFlyout } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'

import { useWhiteLabel } from '../../whiteLabel/WhiteLabelContext'

type AddAccountFlyoutProps = {
  onSubmit?: (account: Account) => void
}

const AddAccountFlyout = ({ onSubmit }: AddAccountFlyoutProps) => {
  const dispatch = useDispatch()
  const { agencySpecialMatchingOffer } = useSelector((state: RootState) => state.app)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [businessType, setBusinessType] = useState<BusinessType | null>(null)
  const whiteLabel = useWhiteLabel()
  const whiteLabelType = whiteLabel?.getWhiteLabel()

  const [createAccount, accountResult] = useCreateAccountMutation()

  useEffect(() => {
    if (accountResult?.isSuccess || accountResult?.isError) {
      closeFlyout()
    }
  }, [accountResult])

  const closeFlyout = () => {
    dispatch(hideAddAccountFlyout())
    formik.resetForm()
  }

  const accountSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the company name'),
    website: Yup.string().url('Please enter a valid URL'),
    businessTypeId: Yup.string(),
    streetAddress: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string()
  })

  if (agencySpecialMatchingOffer) {
    accountSchema.fields.website = accountSchema.fields.website.required()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessTypeId: '',
      name: '',
      streetAddress: '',
      cityName: '',
      state: '',
      country: 'usa',
      zip: '',
      website: ''
    } as CreateAccountRequest,
    validationSchema: accountSchema,
    onSubmit: async (values: CreateAccountRequest) => {
      values.isAgencySpecialMatchingOffer = agencySpecialMatchingOffer
      const result = await createAccount(values).unwrap()
      onSubmit?.(result)
    }
  })

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setBusinessType(businessType)
      formik.setFieldValue('businessTypeId', businessType.id, true)
    }
  }

  return (
    <EuiPortal>
      <EuiFlyout onClose={closeFlyout} size='m' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
        <EuiFlyoutHeader hasBorder>
          {agencySpecialMatchingOffer && (
            <EuiTitle>
              <h4>Add Client With Special Matching Offer</h4>
            </EuiTitle>
          )}
          {!agencySpecialMatchingOffer && (
            <EuiTitle>
              <h4>Add Client</h4>
            </EuiTitle>
          )}
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            <EuiFormRow label='Business Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
              <EuiFieldText name='name' value={formik.values.name} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} />
            </EuiFormRow>

            <EuiFormRow label='Website' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} error={formik.errors.website}>
              <EuiFieldText name='website' value={formik.values.website} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} />
            </EuiFormRow>

            {!agencySpecialMatchingOffer && whiteLabelType !== WhiteLabel.AgencyV2 && (
              <React.Fragment>
                <EuiFormRow label='Business Type' fullWidth isInvalid={formik.touched.businessTypeId && !!formik.errors.businessTypeId} error={formik.errors.businessTypeId}>
                  <React.Fragment>
                    {businessType && (
                      <React.Fragment>
                        <EuiCard
                          display='primary'
                          layout='horizontal'
                          paddingSize='s'
                          titleSize='xs'
                          icon={
                            <div>
                              <img alt='' src={businessType.imageUrl} />
                            </div>
                          }
                          title={businessType.name}
                          description={businessType.businessTypeCategories.join(', ')}
                        />
                        <EuiSpacer size='s' />
                        <EuiLink color='success' onClick={() => setBusinessType(null)}>
                          Clear selected business type
                        </EuiLink>
                      </React.Fragment>
                    )}
                    {!businessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={formik.touched.businessTypeId && !!formik.errors.businessTypeId} />}
                  </React.Fragment>
                </EuiFormRow>

                <EuiSpacer />

                <EuiFormRow label='Street Address' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} error={formik.errors.streetAddress}>
                  <EuiFieldText name='streetAddress' value={formik.values.streetAddress} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} />
                </EuiFormRow>
                <EuiSpacer size='s' />

                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow label='City' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} error={formik.errors.city}>
                      <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                      <EuiSelect
                        name='state'
                        isLoading={dictionary.isLoadingStates}
                        options={dictionary.states.map(
                          s =>
                            ({
                              value: s.code,
                              label: s.name
                            } as EuiSelectOption)
                        )}
                        value={formik.values.state}
                        onChange={value => formik.setFieldValue('state', value.target.value, true)}
                        fullWidth
                        isInvalid={formik.touched.state && !!formik.errors.state}
                        hasNoInitialSelection={true}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label='Zip' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} error={formik.errors.zip}>
                      <EuiFieldText name='zip' value={formik.values.zip} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </React.Fragment>
            )}

            <EuiSpacer />

            <EuiButton id='create' fill type='submit' isLoading={accountResult.isLoading}>
              Create
            </EuiButton>
          </EuiForm>
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty id='close' iconType='cross' onClick={closeFlyout} flush='left'>
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiPortal>
  )
}

export default AddAccountFlyout
