import { useEffect } from 'react'

import { EuiFieldNumber, EuiFormRow, EuiRadioGroup, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

type BudgetStepProps = {
  budget: string
  setBudget: (budget: string) => void
}

export const BudgetStep = ({ budget, setBudget }: BudgetStepProps) => {
  const radios = [
    {
      id: '1',
      label: 'Allow AdCritter AI to suggest a budget'
    },
    {
      id: '2',
      label: 'Use a specific budget range',
      disabled: true
    },
    {
      id: '3',
      label: 'Use a specific amount',
      disabled: true
    }
  ]

  useEffect(() => {
    setBudget('Allow AdCritter AI to suggest a budget')
  }, [])

  return (
    <>
      <EuiTitle size='s'>
        <h2>Budget Recommendation</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiFormRow label={'Use the following budget approach'}>
        <EuiRadioGroup options={radios} idSelected={'1'} onChange={id => {}} name='radio group' />
      </EuiFormRow>
    </>
  )
}
