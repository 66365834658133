import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { EuiPage, EuiPageBody } from '@elastic/eui'

import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcTernary } from 'components/Routing/AcTernary'
import CampaignInsightsPage from 'features/campaigns/campaign/insights/CampaignInsightsPage'

import { WhiteLabel } from '../../api/interfaces'
import { useWhiteLabel } from '../../whiteLabel/WhiteLabelContext'
import StartCreateAdPage from './StartCreateAdPage'
import EditAdPage from './ad/EditAdPage'
import EditCampaignPage from './campaign/EditCampaignPage'
import { NewEditCampaignPage } from './campaign/NewEditCampaignPage/NewEditCampaignPage'
import ListCampaignsPage from './campaigns/ListCampaignsPage'
import CreateAdBillboardsRetailPage from './createAd/billboards/retail/Page'
import CreateAdInternetRetailPage from './createAd/internet/retail/Page'
import CreateAdTvRetailPage from './createAd/tv/retail/Page'

const CampaignsPage: React.FC = () => {
  const { path } = useRouteMatch()
  const campaign = useSelector((state: RootState) => state.campaign)
  const whiteLabel = useWhiteLabel()

  const whiteLabelType = whiteLabel?.getWhiteLabel()

  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <Switch>
          <Route exact path={path}>
            {<ListCampaignsPage />}
          </Route>
          <Route path={`${path}/insights/:campaignId`}>
            <CampaignInsightsPage />
          </Route>
          <Route path={`${path}/edit/:campaignId`}>{whiteLabelType !== WhiteLabel.AgencyV2 ? <EditCampaignPage /> : <NewEditCampaignPage />}</Route>
          <Route path={`${path}/createAd/:campaignId`}>
            <StartCreateAdPage />
          </Route>
          <Route path={`${path}/ads/:campaignId/edit/:adId`}>
            <EditAdPage />
          </Route>
          <Route exact path={`${path}/internet/public/:step`}>
            <AcTernary hasRequiredSetup={() => !!campaign.campaign?.id} failRender={() => <Redirect to={`/campaigns`} />} succeedRender={() => <CreateAdInternetRetailPage />} />
          </Route>
          <Route exact path={`${path}/billboards/public/:step`}>
            <AcTernary hasRequiredSetup={() => !!campaign.campaign?.id && config.features.billboardSuggestionsEnabled} failRender={() => <Redirect to={`/campaigns`} />} succeedRender={() => <CreateAdBillboardsRetailPage />} />
          </Route>
          <Route exact path={`${path}/tv/public/:step`}>
            <AcTernary hasRequiredSetup={() => !!campaign.campaign?.id} failRender={() => <Redirect to={`/campaigns`} />} succeedRender={() => <CreateAdTvRetailPage />} />
          </Route>
        </Switch>
      </EuiPageBody>
    </EuiPage>
  )
}

export default CampaignsPage
