import mapboxgl from 'mapbox-gl'
import { useEffect, useRef } from 'react'

import config from '../../app/config'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const InitialMapCenter: mapboxgl.LngLatLike = [-98.5795, 39.8283]

const InitialMapZoom = 3

export interface IMapBoxContainer {
  mapRef: React.MutableRefObject<any>
}

export const MapBoxContainer: React.FC<IMapBoxContainer> = props => {
  const { mapRef } = props
  const mapContainerRef = useRef<any>()

  useEffect(() => {
    if (!mapContainerRef.current) {
      return
    }

    mapboxgl.accessToken = config.mapBox.token
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: InitialMapCenter,
      zoom: InitialMapZoom
    })
    setTimeout(() => mapRef.current.resize(), 100)
  }, [mapRef, mapContainerRef])

  return <div style={{ backgroundColor: 'lightgrey', width: '100%', height: '100%' }} ref={mapContainerRef} />
}
