import { EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'
import { NewSegmentFinder } from '../../components/finders/NewSegmentFinder'
import { CampaignType } from '../../api/interfaces'
import React from 'react'

export const TvInventoryExplorer: React.FC = () => {
  return (
    <>
      <EuiTitle size={'s'}>
        <h2>Explore TV Audiences</h2>
      </EuiTitle>
      <EuiSpacer/>
      <EuiFormRow label={'Audience Segments'} fullWidth>
        <NewSegmentFinder campaignType={CampaignType.TV} isInvalid={false} onSegmentClicked={() => {}} />
      </EuiFormRow>
    </>
  )
}