import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { PermissionType } from '../../../api/interfaces'
import { useBeginCreateClientPlanMutation, useGetClientPlansQuery } from '../../../api/rtkQueryApi/platform/clientPlansApi'
import { RootState } from '../../../app/rootReducer'
import { useOrganizationPermissions } from '../../../providers/OrganizationPermissionsContext'
import history from '../../../services/HistoryService'
import { isValidURL } from '../../../utils/isValidUrl'
import { BudgetStep } from '../BudgetStep'
import { ClientPickerStep } from '../ClientPickerStep'
import { ClientPlanConfirmCreateStep } from '../ClientPlanConfirmCreateStep'
import { ClientPlanCreateStep, setStep, reset, setAccountId, setAccountName, setUrl, setDetails, setBudget } from '../CreateClientPlanSlice'
import { EnterUrlStep } from '../EnterUrlStep'
import { AdditionalDetailsStep } from './AdditionalDetailsStep'

export const ClientPlansCreatePage = () => {
  const { createClientPlan } = useSelector((state: RootState) => state)
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const { hasPermission } = useOrganizationPermissions()
  const getClientPlansQuery = useGetClientPlansQuery({ organizationId: currentOrganization?.id ?? '' }, { skip: !hasPermission(PermissionType.UseAdCritterAi) })
  const [createClientPlanMutation, createClientPlanMutationResult] = useBeginCreateClientPlanMutation()
  const [hasClientPlans, setHasClientPlans] = React.useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  useEffect(() => {
    if (getClientPlansQuery.data) {
      setHasClientPlans(getClientPlansQuery.data.length > 0)
    }
  }, [getClientPlansQuery.data])

  const callCreateClientPlan = useCallback(async () => {
    if (!currentOrganization) {
      return
    }
    await createClientPlanMutation({
      organizationId: currentOrganization.id,
      params: {
        businessName: createClientPlan.accountName,
        accountId: createClientPlan.accountId,
        website: createClientPlan.url,
        budget: createClientPlan.budget,
        details: createClientPlan.details
      }
    })
  }, [createClientPlan, currentOrganization])

  const handleContinue = () => {
    switch (createClientPlan.step) {
      case ClientPlanCreateStep.PickClient:
        dispatch(setStep(ClientPlanCreateStep.SetUrl))
        // TODO: Add client button
        break
      case ClientPlanCreateStep.SetUrl:
        dispatch(setStep(ClientPlanCreateStep.SetBudget))
        break
      case ClientPlanCreateStep.SetBudget:
        dispatch(setStep(ClientPlanCreateStep.AddAdditionalDetails))
        break
      case ClientPlanCreateStep.AddAdditionalDetails:
        dispatch(setStep(ClientPlanCreateStep.Confirmation))
        break
      case ClientPlanCreateStep.Confirmation:
        callCreateClientPlan().then(() => {
          dispatch(reset())
        })
        break
      default:
        break
    }
  }

  const handleBack = () => {
    switch (createClientPlan.step) {
      case ClientPlanCreateStep.SetUrl:
        dispatch(setStep(ClientPlanCreateStep.PickClient))
        break
      case ClientPlanCreateStep.SetBudget:
        dispatch(setStep(ClientPlanCreateStep.SetUrl))
        break
      case ClientPlanCreateStep.AddAdditionalDetails:
        dispatch(setStep(ClientPlanCreateStep.SetBudget))
        break
      case ClientPlanCreateStep.Confirmation:
        callCreateClientPlan().then(() => {
          history.push('/ai')
        })
        break
      case ClientPlanCreateStep.PickClient:
        if (hasClientPlans) {
          history.push('/ai')
        } else {
          history.push('/ai/welcome')
        }
        break
      default:
        break
    }
  }

  const continueDisabled = () => {
    switch (createClientPlan.step) {
      case ClientPlanCreateStep.PickClient:
        return createClientPlan.accountId === ''
      case ClientPlanCreateStep.SetUrl:
        return !isValidURL(createClientPlan.url)
      case ClientPlanCreateStep.SetBudget:
        return parseInt(createClientPlan.budget) <= 0
      default:
        return false
    }
  }

  const getConfirmText = (step: ClientPlanCreateStep) => {
    switch (step) {
      case ClientPlanCreateStep.SetUrl:
        return 'Confirm'
      case ClientPlanCreateStep.Confirmation:
        return 'New Plan'
      default:
        return 'Continue'
    }
  }

  const getBackText = (step: ClientPlanCreateStep) => {
    switch (step) {
      case ClientPlanCreateStep.Confirmation:
        return 'Done'
      default:
        return 'Back'
    }
  }

  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1> AdCritter AI </h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className='eui-xScroll'>
          {createClientPlan.step === ClientPlanCreateStep.PickClient && <ClientPickerStep accountId={createClientPlan.accountId} accountName={createClientPlan.accountName} setAccountId={accountId => dispatch(setAccountId(accountId))} setAccountName={accountName => dispatch(setAccountName(accountName))} setUrl={url => dispatch(setUrl(url))} />}
          {createClientPlan.step === ClientPlanCreateStep.SetUrl && <EnterUrlStep url={createClientPlan.url} setUrl={url => dispatch(setUrl(url))} clientName={createClientPlan.accountName} />}
          {createClientPlan.step === ClientPlanCreateStep.SetBudget && <BudgetStep budget={createClientPlan.budget} setBudget={budget => dispatch(setBudget(budget))} />}
          {createClientPlan.step === ClientPlanCreateStep.AddAdditionalDetails && <AdditionalDetailsStep details={createClientPlan.details} setDetails={details => dispatch(setDetails(details))} />}
          {createClientPlan.step === ClientPlanCreateStep.Confirmation && <ClientPlanConfirmCreateStep />}
          <EuiSpacer />
          <EuiFlexGroup justifyContent={'flexStart'}>
            <EuiFlexItem onClick={handleBack} grow={false}>
              <EuiButton isLoading={createClientPlanMutationResult.isLoading}>{getBackText(createClientPlan.step)}</EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton fill onClick={handleContinue} isLoading={createClientPlanMutationResult.isLoading} disabled={continueDisabled()}>
                {getConfirmText(createClientPlan.step)}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  )
}
