import React, { useEffect } from 'react'
import { MapBoxContainer } from './MapBoxContainer'
import { CreateGeoJsonPoints } from './GeoJsonPoints'
import { GeoPoint } from '../../api/interfaces'
import config from '../../app/config'

export interface MapBoxPlaceResponse {
  attribution: string
  features: MapBoxPlaceFeature[]
  type: string
}

export interface MapBoxPlaceFeature {
  id: string
  type: string
  geometry: {
    type: string
    coordinates: [number, number]
  }
}

export const useMapBox = () => {
  const [center, setCenter] = React.useState<GeoPoint>()
  const [zoom, setZoom] = React.useState<number>()

  const mapBoxRef = React.useRef<any>()
  const markers = React.useRef<any[]>([])
  const accessToken = config.mapBox.token
  const [isReady, setIsReady] = React.useState(false)


  const map =
    <MapBoxContainer mapRef={mapBoxRef} />

  useEffect(() => {
    mapBoxRef.current.on('load', () => {
      mapBoxRef.current.addSource('points', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        },
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 50
      });

      mapBoxRef.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#005ccc',
            50,
            '#005ccc'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            50,
            22,
            100,
            25,
            200,
            30
          ]
        }
      });

      mapBoxRef.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'points',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#26374d',
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      mapBoxRef.current.setPaintProperty(
        'clusters',
        'circle-opacity',
        .6
      );

      setIsReady(true)
    });

    mapBoxRef.current.on('move', () => {
      const mapCenter = mapBoxRef.current.getCenter()
      const mapZoom = mapBoxRef.current.getZoom()
      setCenter({ lat: mapCenter.lat, lng: mapCenter.lng })
      setZoom(mapZoom)
    })
  }, [])

  const flyTo = (center: [number, number], zoom: number) => {
    mapBoxRef.current.flyTo({ center, zoom })
  }

  const searchPlace = async (place: string) => {
    const requestUrl = `https://api.mapbox.com/search/geocode/v6/forward?q=${place}&worldview=cn&access_token=${accessToken}`
    const response = await fetch(requestUrl)
      .then(response => {
        return response.json()
      })
    return response as MapBoxPlaceResponse
  }

  const setMarkers = (points: GeoPoint[]) => {
    if(points.length === 0 || !mapBoxRef.current) {
      return
    }
    const geoJsonPoints = CreateGeoJsonPoints(points)
    mapBoxRef.current.getSource('points').setData(geoJsonPoints.data)
  }

  const clearMarkers = () => {
    markers.current.forEach(marker => {
      marker.remove()
    })
    markers.current = []
  }

  return {
    isReady: isReady,
    map: map,
    flyTo: flyTo,
    searchPlace: searchPlace,
    center: center,
    zoom: zoom,
    setMarkers: setMarkers,
    clearMarkers: clearMarkers
  }
}