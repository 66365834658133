import { useDispatch } from 'react-redux'

import { Campaign, CampaignType } from '../../../../../api/interfaces'
import { BaseTargeting, InternetTargeting, TvTargeting } from '../../../../../api/interfaces/TargetingTemplate'
import { useImportBillboardTargetingTemplateMutation, useImportInternetTargetingTemplateMutation, useImportTvTargetingTemplateMutation } from '../../../../../api/rtkQueryApi/platform/campaignsApi'
import { setCampaign } from '../../campaignSlice'

export const useUpdateCampaignTargeting = () => {
  const [importInternetTargetingMutation, importInternetTargetingMutationResult] = useImportInternetTargetingTemplateMutation()
  const [importTvTargetingMutation, importTvTargetingMutationResult] = useImportTvTargetingTemplateMutation()
  const [importBillboardTargetingMutation, importBillboardTargetingMutationResult] = useImportBillboardTargetingTemplateMutation()

  const dispatch = useDispatch()

  const updateCampaignTargeting = async (campaign: Campaign, targeting: InternetTargeting | TvTargeting | BaseTargeting) => {
    switch (campaign.type) {
      case CampaignType.Internet:
        const internetCampaign = await importInternetTargetingMutation({
          campaignId: campaign.id,
          targeting: {
            ...(targeting as InternetTargeting)
          },
          accountId: campaign.accountId
        }).unwrap()
        dispatch(setCampaign(internetCampaign))
        break
      case CampaignType.TV:
        const tvCampaign = await importTvTargetingMutation({
          campaignId: campaign.id,
          targeting: {
            ...(targeting as TvTargeting)
          },
          accountId: campaign.accountId
        }).unwrap()
        dispatch(setCampaign(tvCampaign))
        break
      case CampaignType.Billboard:
        const billboardCampagin = await importBillboardTargetingMutation({
          campaignId: campaign.id,
          targeting: {
            ...(targeting as BaseTargeting)
          },
          accountId: campaign.accountId
        }).unwrap()
        dispatch(setCampaign(billboardCampagin))
        break
      default:
        return null
    }
  }

  return { updateCampaignTargeting, importInternetTargetingMutationResult, importTvTargetingMutationResult, importBillboardTargetingMutationResult }
}
