import { Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import { ClientPlansPage } from './pages/ClientPlansPage'
import { ClientPlansCreatePage } from './pages/ClientPlansCreatePage'
import { ClientPlanPage } from './pages/ClientPlanPage'
import { ClientPlanWelcomePage } from './pages/ClientPlanWelcomePage'

export const AiRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact={true} path={path}>
        <ClientPlansPage />
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/welcome`}>
        <ClientPlanWelcomePage />
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/create-plan`}>
        <ClientPlansCreatePage />
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/:clientPlanId`}>
        <ClientPlanPage />
      </PrivateRoute>
    </Switch>
  )
}