import moment, { Moment } from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  BuilderCampaignType,
  CampaignCreateStep,
  CampaignCreateWorkflow,
  CreativeBuilderStep,
  CreativeBuilderType,
  CreativeCreateStep,
  CreativeType,
  DeliverStep,
  TargetStep
} from 'workflows/CampaignCreateWorkflow'

import {
  EuiCallOut,
  EuiCheckbox,
  EuiDatePicker,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow, EuiPage, EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiRadio,
  EuiTitle
} from '@elastic/eui'

import { CampaignBuilderActivateResponse, DeviceSettings, FrequencyUnit, InventorySettings } from 'api/interfaces'
import { LocationTargetingType, TargetingType } from 'api/interfaces/CampaignTargeting'
import { BudgetFrequency } from 'api/interfaces/PaymentInfo'
import { useCampaignBuilderActivateMutation } from 'api/rtkQueryApi/platform/campaignBuilderApi'
import { useCreateDisplayAdsMutation } from 'api/rtkQueryApi/platform/creativesApi'
import { showWarningToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import { UploadedImage } from 'components/Basic/AcImageUploader'
import { DisplayAdUploader } from 'components/adCreation/DisplayAdUploader'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { useCampaignCreateSyncManager } from 'features/builders/createCampaign/useCampaignCreateSyncManager'
import { AdPaymentType, AdPlacementType } from 'features/builders/internet/retail/builderInternetRetailSlice'
import history from 'services/HistoryService'
import { useDebounceEffect } from 'utils/useDebounceEffect'
import { TvAdUploader } from '../../../components/adCreation/TvAdUploader'
import { UploadedVideo } from '../../../components/Basic/AcVideoUploader'

export const QuickCampaignPage: React.FC = () => {
  const [start, setStart] = React.useState<Moment | null>(null)
  const [end, setEnd] = React.useState<Moment | null>(null)
  const [campaignName, setCampaignName] = React.useState<string>('')
  const [isValidUpload, setIsValidUpload] = React.useState<boolean>(true)
  const [audienceUrl, setAudienceUrl] = React.useState<string>('')
  const [clickUrl, setClickUrl] = React.useState<string>('')
  const [budget, setBudget] = React.useState<number>(250)
  const [impressionFrequency, setImpressionFrequency] = React.useState<number>(6)
  const [targetCPM, setTargetCPM] = React.useState<number>(7)
  const [addressCount, setAddressCount] = React.useState<number>(0)
  const [campaignBuilderActivate, campaignBuilderActivateQuery] = useCampaignBuilderActivateMutation()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [createDisplayAds, createDisplayAdsRequest] = useCreateDisplayAdsMutation()
  const [deviceSettings, setDeviceSettings] = React.useState<DeviceSettings>({
    targetPc: true,
    targetPhone: true,
    targetTablet: true
  })
  const [inventorySettings, setInventorySettings] = React.useState<InventorySettings>({
    targetApp: false,
    targetWeb: true
  })
  const [campaignType, setCampaignType] = React.useState<BuilderCampaignType>(BuilderCampaignType.Display)

  const [isFileLoading, setIsFileLoading] = React.useState<boolean>(false)
  const [uploadedImages, setUploadedImages] = React.useState<UploadedImage[]>([])
  const [invalidImages, setInvalidImages] = React.useState<UploadedImage[]>([])

  const [uploadedVideos, setUploadedVideos] = React.useState<UploadedVideo[]>([])
  const [invalidVideos, setInvalidVideos] = React.useState<UploadedVideo[]>([])

  const minBudget = 100

  const dispatch = useDispatch()
  useCampaignCreateSyncManager()

  useDebounceEffect(
    1000,
    () => {
      if (audienceUrl.length > 0) {
        getAudienceSize(audienceUrl)
      } else {
        setAddressCount(0)
      }
    },
    [audienceUrl]
  )

  const getAudienceSize = (url: string) => {
    setIsFileLoading(true)
    setTimeout(() => {
      var request = new XMLHttpRequest()
      request.open('GET', url, true)
      request.responseType = 'blob'
      request.onload = function() {
        var reader = new FileReader()
        reader.readAsText(request.response)
        reader.onload = function(e) {
          let csv = e.target?.result as string
          setIsFileLoading(false)
          setAddressCount(csv.split('\n').length - 1)
        }
      }
      request.send()
    }, 1000)
  }

  const createDisplayCampaign = (campaignCreate: CampaignCreateWorkflow, imagesToUpload: UploadedImage[]) => {
    campaignBuilderActivate({
      accountId: currentAccount!.id,
      workflow: campaignCreate,
      purchaseDetails: {
        paymentMethodType: AdPaymentType.Standard,
        paymentMethodId: '',
        budgetFrequency: BudgetFrequency.Total
      },
      isSaveForLater: true
    }).then(c => {
      let result: CampaignBuilderActivateResponse | undefined = undefined
      if ('data' in c) {
        result = c.data
      }
      if (result?.campaignCreated && result?.campaignId) {
        createDisplayAds({
          accountId: currentAccount!.id,
          campaignId: result!.campaignId,
          displayAds: [...imagesToUpload].map(image => ({
            name: image.fileName.split('.')[0],
            destinationUrl: clickUrl,
            companyName: currentAccount!.name,
            uploadedUrl: image.url,
            htmlTag: '',
            width: image.width,
            height: image.height,
            mimeType: `image/${getFileType(image.fileName)}`,
            campaignIds: [result!.campaignId]
          }))
        }).then(() => {
          history.push(`/campaigns/edit/${result!.campaignId}`)
        })
      }
    })
  }

  const createTvCampaign = (campaignCreate: CampaignCreateWorkflow) => {
    campaignBuilderActivate({
      accountId: currentAccount!.id,
      workflow: campaignCreate,
      purchaseDetails: {
        paymentMethodType: AdPaymentType.Standard,
        paymentMethodId: '',
        budgetFrequency: BudgetFrequency.Total
      },
      isSaveForLater: true
    }).then(c => {
      let result: CampaignBuilderActivateResponse | undefined = undefined
      if ('data' in c) {
        result = c.data
        history.push(`/campaigns/edit/${result.campaignId}`)
      }
    })
  }

  const getFileType = (fileName: string) => {
    const parts = fileName.split('.')
    if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
      return ''
    }
    return parts.pop()
  }

  const onUploadDisplayClick = (images: UploadedImage[]) => {
    if (images.length > 0) {
      const campaignCreate: CampaignCreateWorkflow = {
        id: uuidv4(),
        campaignCreateStep: CampaignCreateStep.CreativeCreate,
        campaignType: campaignType,
        createWorkflow: {
          creativeCreateStep: CreativeCreateStep.CampaignTypeSelect,
          creativeBuilderWorkflow: {
            creativeBuilderType: CreativeBuilderType.PreMade,
            creativeBuilderStep: CreativeBuilderStep.BusinessInfo
          }
        },
        targetWorkflow: {
          targetStep: TargetStep.Choose
        },
        deliverWorkflow: {
          deliverStep: DeliverStep.Delivery
        },
        campaignInfo: {
          name: campaignName,
          budget: budget,
          contactName: '',
          email: '',
          phone: '',
          startDate: moment(start),
          endDate: moment(end),
          isTotalBudget: true
        },
        creativeInfo: {
          creativeId: '',
          creativeType: CreativeType.Display,
          displayDetails: [
            {
              fileName: images[0].fileName.split('.')[0],
              displayImageUrl: images[0].url,
              displayHeight: images[0].height,
              displayWidth: images[0].width,
              clickUrl: clickUrl
            }
          ]
        },
        businessInfo: {},
        campaignTargeting: {
          smartTargeting: {
            streetAddress: '',
            city: '',
            state: '',
            zip: '',
            walkInTargeting: false,
            walkInUsePrimary: false,
            walkInAddresses: [],
            competitorTargeting: false,
            competitors: []
          },
          locationTargeting: {
            targetingType: LocationTargetingType.Region,
            regions: [],
            dmas: [],
            cities: [],
            zips: []
          },
          campaignDelivery: {
            dayParts: DefaultDayParts,
            frequency: impressionFrequency,
            frequencyUnit: FrequencyUnit.week,
            inventorySettings: inventorySettings,
            deviceSettings: deviceSettings,
            targetCPM: targetCPM,
            placement: AdPlacementType.Everywhere,
            categories: [],
            specific: []
          },
          targetingType: TargetingType.Audience,
          audienceTargeting: {
            demographics: {
              ageRanges: [],
              genders: [],
              householdIncomes: []
            },
            segments: [],
            uploadedAudienceUrl: audienceUrl
          }
        }
      }
      const imagesToAdd = images.slice(1)
      createDisplayCampaign(campaignCreate, imagesToAdd)
    } else {
      dispatch(showWarningToast('Please upload at least one image'))
    }
  }

  const onUploadVideoClick = (videos: UploadedVideo[]) => {
    if (videos.length > 0) {
      const campaignCreate: CampaignCreateWorkflow = {
        id: uuidv4(),
        campaignCreateStep: CampaignCreateStep.CreativeCreate,
        campaignType: campaignType,
        createWorkflow: {
          creativeCreateStep: CreativeCreateStep.CampaignTypeSelect,
          creativeBuilderWorkflow: {
            creativeBuilderType: CreativeBuilderType.PreMade,
            creativeBuilderStep: CreativeBuilderStep.BusinessInfo
          }
        },
        targetWorkflow: {
          targetStep: TargetStep.Choose
        },
        deliverWorkflow: {
          deliverStep: DeliverStep.Delivery
        },
        campaignInfo: {
          name: campaignName,
          budget: budget,
          contactName: '',
          email: '',
          phone: '',
          startDate: moment(start),
          endDate: moment(end),
          isTotalBudget: true
        },
        creativeInfo: {
          creativeId: '',
          creativeType: CreativeType.Video,
          videoDetails:
            videos.map(video => ({
              baseVideoId: '',
              creativeId: '',
              kind: '',
              name: video.fileName.split('.')[0],
              previewUrl: '',
              quality: '',
              script: '',
              sections: [],
              thumbnailUrl: '',
              type: '',
              uploadedUrl: video.url
            }))
        },
        businessInfo: {},
        campaignTargeting: {
          smartTargeting: {
            streetAddress: '',
            city: '',
            state: '',
            zip: '',
            walkInTargeting: false,
            walkInUsePrimary: false,
            walkInAddresses: [],
            competitorTargeting: false,
            competitors: []
          },
          locationTargeting: {
            targetingType: LocationTargetingType.USA,
            regions: [],
            dmas: [],
            cities: [],
            zips: [],
            uploadedAudienceUrl: audienceUrl
          },
          campaignDelivery: {
            dayParts: DefaultDayParts,
            frequency: 0,
            frequencyUnit: FrequencyUnit.hour,
            inventorySettings: { targetApp: false, targetWeb: true },
            deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
            placement: AdPlacementType.Everywhere,
            categories: [],
            specific: []
          },
          targetingType: TargetingType.Location,
          audienceTargeting: {
            demographics: {
              ageRanges: [],
              genders: [],
              householdIncomes: []
            },
            segments: []
          }
        }
      }
      createTvCampaign(campaignCreate)
    } else {
      dispatch(showWarningToast('Please upload at least one image'))
    }
  }

  const isValid = () => {
    return start && start > moment().add(-1, 'days') && end && end > start && campaignName.length > 0 && addressCount > 0 && impressionFrequency > 0 && targetCPM > 0 && budget >= minBudget && uploadedImages.length > 0 && clickUrl
  }

  return (
    <EuiPage className="euiPage--platform">
      <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>New Display Campaign{campaignName.length > 0 ? ` (${campaignName})` : ''}</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPanel grow={false} className="eui-xScroll">
            <EuiFormRow label="Campaign Name" isInvalid={campaignName.length < 1}>
              <EuiFieldText
                value={campaignName}
                onChange={e => {
                  setCampaignName(e.target.value)
                }}
              />
            </EuiFormRow>
            <EuiFormRow>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiFormRow label={'Start Date'}>
                    <EuiDatePicker fullWidth selected={start} dateFormat="MM/DD/yyyy" onChange={d => setStart(d)}
                                   minDate={moment()} showTimeSelect={false} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow label={'End Date'}>
                    <EuiDatePicker fullWidth selected={end} dateFormat="MM/DD/yyyy" onChange={d => setEnd(d)}
                                   minDate={start ?? moment()} showTimeSelect={false} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
            <EuiFormRow label="Target Audience">
              <React.Fragment>
                <AcFileUploader
                  maxFileSize={10000000}
                  allowedFileTypes={['text/plain', 'text/csv']}
                  onUploadedUrlChange={url => {
                    setAudienceUrl(url)
                  }}
                  onIsValidFileChange={isValid => {
                    setIsValidUpload(isValid)
                  }}
                  isLoading={isFileLoading}
                />
                {addressCount > 0 && !isFileLoading &&
                  <EuiCallOut title={`${addressCount} addresses`} iconType="document" color="success" size={'s'} />}
                {!isValidUpload &&
                  <EuiCallOut title="Please upload a csv under 10MB" iconType="document" color="warning" />}
              </React.Fragment>
            </EuiFormRow>
            <EuiFormRow label={'Campaign Type'}>
              <EuiFlexGroup wrap>
                <EuiFlexItem grow={false}>
                  <EuiRadio id={'internet'} onChange={() => {
                    setCampaignType(BuilderCampaignType.Display)
                  }} label={'Internet'} checked={campaignType === BuilderCampaignType.Display} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiRadio id={'tv'} onChange={() => {
                    setCampaignType(BuilderCampaignType.TV)
                  }} label={'TV'} checked={campaignType === BuilderCampaignType.TV} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
            {campaignType === BuilderCampaignType.Display && (
              <EuiFormRow isInvalid={impressionFrequency < 1 || targetCPM < 0} label={'Delivery'}>
                <React.Fragment>
                  <EuiFlexGrid columns={2}>
                    <EuiFlexItem>
                      <EuiFieldNumber fullWidth value={impressionFrequency} step={1}
                                      onChange={e => setImpressionFrequency(parseInt(e.target.value))}
                                      append={'views / user'} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFieldNumber prepend={'$'} fullWidth value={targetCPM}
                                      onChange={e => setTargetCPM(parseInt(e.target.value))} append={'cpm'} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiCheckbox id={'targetApp'} onChange={() => setInventorySettings({
                        ...inventorySettings,
                        targetApp: !inventorySettings.targetApp
                      })} checked={inventorySettings.targetApp} label={'Target App'} />
                      <EuiCheckbox id={'targetWeb'} onChange={() => setInventorySettings({
                        ...inventorySettings,
                        targetWeb: !inventorySettings.targetWeb
                      })} checked={inventorySettings.targetWeb} label={'Target Web'} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiCheckbox id={'pc'} onChange={() => setDeviceSettings({
                        ...deviceSettings,
                        targetPc: !deviceSettings.targetPc
                      })} checked={deviceSettings.targetPc} label={'Target PC'} />
                      <EuiCheckbox id={'phone'} onChange={() => setDeviceSettings({
                        ...deviceSettings,
                        targetPhone: !deviceSettings.targetPhone
                      })} checked={deviceSettings.targetPhone} label={'Target Phone'} />
                      <EuiCheckbox id={'tablet'} onChange={() => setDeviceSettings({
                        ...deviceSettings,
                        targetTablet: !deviceSettings.targetTablet
                      })} checked={deviceSettings.targetTablet} label={'Target Tablet'} />
                    </EuiFlexItem>
                  </EuiFlexGrid>
                </React.Fragment>
              </EuiFormRow>)}
            <EuiFormRow label={'Estimated Budget (Max)'} isInvalid={budget < minBudget}
                        error={'Budget must be >= $' + minBudget}>
              <EuiFieldNumber
                fullWidth
                prepend={'$'}
                value={budget || ''}
                onChange={e => {
                  setBudget(parseFloat(e.target.value))
                }}
                isInvalid={budget < minBudget}
              />
            </EuiFormRow>
            {campaignType === BuilderCampaignType.Display && (
              <DisplayAdUploader uploadedImages={uploadedImages} invalidImages={invalidImages} websiteLink={clickUrl}
                                 isLoading={campaignBuilderActivateQuery.isLoading || createDisplayAdsRequest.isLoading}
                                 onUpdateWebsiteLink={l => setClickUrl(l)} onUploadClick={onUploadDisplayClick}
                                 onUploadedImagesChange={setUploadedImages} onInvalidImagesChange={setInvalidImages}
                                 isDisabled={!isValid()} />
            )}
            {campaignType === BuilderCampaignType.TV && (
              <TvAdUploader uploadedVideos={uploadedVideos} invalidVideos={invalidVideos}
                            isLoading={campaignBuilderActivateQuery.isLoading} onInvalidVideosChange={() => {
              }} onUploadClick={onUploadVideoClick} websiteLink={clickUrl} onUpdateWebsiteLink={l => setClickUrl(l)}
                            onUploadedVideosChange={videos => {
                              setUploadedVideos(videos)
                            }} />
            )}
          </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  )
}
