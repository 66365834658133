import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignType } from '../../../../../api/interfaces'
import { RootState } from '../../../../../app/rootReducer'
import { AudienceTargetingForm } from '../../../../../components/targeting/TargetingForm/AudienceTargetingForm'
import { SegmentsForm } from '../../../../../components/targeting/TargetingForm/SegmentsForm'
import { DEFAULT_AUDIENCE_TARGETING } from '../../../../../utils/TargetingFormDefaults'
import { GetInternetTargetingTemplateFromCampaign, GetTvTargetingTemplateFromCampaign } from '../../../../../utils/TransformTargeting'
import { useUpdateCampaignTargeting } from './useUpdateCampaignTargeting'

export const EditAudienceTargeting = () => {
  const { campaign } = useSelector((state: RootState) => state.campaign)

  const getTargeting = () => {
    switch (campaign?.type) {
      case CampaignType.TV:
        return GetTvTargetingTemplateFromCampaign(campaign)
      case CampaignType.Internet:
        return GetInternetTargetingTemplateFromCampaign(campaign)
      default:
        break
    }
  }

  const targeting = campaign && getTargeting()
  const [audienceTargeting, setAudienceTargeting] = useState(targeting?.audienceTargeting)
  const [segments, setSegments] = useState(targeting?.segments)

  const { updateCampaignTargeting, importInternetTargetingMutationResult, importTvTargetingMutationResult } = useUpdateCampaignTargeting()

  if (!targeting) {
    return null
  }

  const onSave = async () => {
    if (!campaign) {
      return
    }
    await updateCampaignTargeting(campaign, {
      ...targeting,
      audienceTargeting,
      segments
    })
  }

  return (
    <>
      <EuiTitle size={'xs'}>
        <h3>Demographics</h3>
      </EuiTitle>
      <EuiSpacer size='xs' />
      <EuiFormRow fullWidth>
        <AudienceTargetingForm audienceTargeting={audienceTargeting ?? DEFAULT_AUDIENCE_TARGETING} onAudienceTargetingChanged={setAudienceTargeting} />
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle size={'xs'}>
        <h3>Segments</h3>
      </EuiTitle>
      <EuiSpacer size='xs' />
      <SegmentsForm campaignType={campaign.type} segments={segments ?? []} onSegmentsChange={setSegments} />
      <EuiButton onClick={onSave} isLoading={importInternetTargetingMutationResult.isLoading || importTvTargetingMutationResult.isLoading}>
        Save
      </EuiButton>
    </>
  )
}
