import { EuiCallOut, EuiFormRow, EuiRadio, EuiSpacer, EuiText } from '@elastic/eui'

import { LocationTargetingType } from '../../../api/interfaces/CampaignTargeting'
import { Address, City, Dma, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, PostalCode, Region } from '../../../api/interfaces/TargetingTemplate'
import { AcTargeterSelection } from '../finders/AcTargeterSelection'
import { CityFinderXandr } from '../finders/CityFinderXandr'
import { DMAFinderXandr } from '../finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from '../finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from '../finders/RegionFinderXandr'
import TargetingMap from './TargetingMap'

export interface LocationTargetingFields {
  locationType: LocationTargetingType
  regions: Region[]
  dmas: Dma[]
  cities: City[]
  postalCodes: PostalCode[]
  uploadedAudienceUrl?: string
  geoFences?: GeoFence[]
  geoCircles?: GeoCircle[]
  geoPolygons?: GeoPolygon[]
  geoRectangles?: GeoRectangle[]
  addresses?: Address[]
}

interface LocationTargetingFormProps {
  locationTargeting: LocationTargetingFields
  onLocationTargetingChange: (locationTargeting: LocationTargetingFields) => void
  selectedLocationType: LocationTargetingType
  onSelectedLocationTypeChange: (locationType: LocationTargetingType) => void
  useDefault?: boolean
  includeLocationTypes?: LocationTargetingType[]
}

export const LocationTargetingForm = ({ locationTargeting, onLocationTargetingChange, includeLocationTypes = [], useDefault, selectedLocationType, onSelectedLocationTypeChange }: LocationTargetingFormProps) => {
  if (useDefault) includeLocationTypes = [LocationTargetingType.USA, LocationTargetingType.Region, LocationTargetingType.DMA, LocationTargetingType.Zip]

  const defaultLocationTypes = [LocationTargetingType.USA, LocationTargetingType.Region, LocationTargetingType.DMA, LocationTargetingType.Zip]

  const options = useDefault ? defaultLocationTypes : [...includeLocationTypes]

  return (
    <>
      {(options.includes(LocationTargetingType.USA) || options.includes(LocationTargetingType.USA)) && (
        <>
          <EuiRadio
            id='usa'
            name='locationType'
            value='Country'
            label={
              <EuiText size='s'>
                <strong>Target the USA</strong>
              </EuiText>
            }
            checked={selectedLocationType === LocationTargetingType.USA}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.USA })
              onSelectedLocationTypeChange(LocationTargetingType.USA)
            }}
          />
          <EuiSpacer size='xs' />
        </>
      )}
      {options.includes(LocationTargetingType.Region) && (
        <>
          <EuiRadio
            id='state'
            name='locationType'
            value='Region'
            label={
              <EuiText size='s'>
                <strong>Targeting Using State</strong> (one or more U.S. States)
              </EuiText>
            }
            checked={selectedLocationType === LocationTargetingType.Region}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Region })
              onSelectedLocationTypeChange(LocationTargetingType.Region)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationTargetingType.DMA) && (
        <>
          <EuiRadio
            id='dma'
            name='locationType'
            value='DMA'
            label={
              <EuiText size='s'>
                <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
              </EuiText>
            }
            checked={selectedLocationType === LocationTargetingType.DMA}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.DMA })
              onSelectedLocationTypeChange(LocationTargetingType.DMA)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationTargetingType.City) && (
        <>
          <EuiRadio
            id='city'
            name='locationType'
            value='City'
            label={
              <EuiText size='s'>
                <strong>Targeting Using City</strong> (one or more City names)
              </EuiText>
            }
            checked={selectedLocationType === LocationTargetingType.City}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.City })
              onSelectedLocationTypeChange(LocationTargetingType.City)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationTargetingType.Zip) && (
        <>
          <EuiRadio
            id='zip'
            name='locationType'
            value='Zip'
            label={
              <EuiText size='s'>
                <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
              </EuiText>
            }
            checked={selectedLocationType === LocationTargetingType.Zip}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Zip })
              onSelectedLocationTypeChange(LocationTargetingType.Zip)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationTargetingType.Map) && (
        <>
          <EuiRadio
            id='map'
            name='locationType'
            value='Map'
            label={
              <EuiText size='s'>
                <strong>Target on Map</strong> (draw the areas you want to target on a map)
              </EuiText>
            }
            checked={selectedLocationType === LocationTargetingType.Map}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Map })
              onSelectedLocationTypeChange(LocationTargetingType.Map)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {selectedLocationType === LocationTargetingType.Region && options.includes(LocationTargetingType.Region) && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({ ...locationTargeting, regions: [...(locationTargeting.regions ?? []), region] })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationTargetingType.DMA && options.includes(LocationTargetingType.DMA) && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({ ...locationTargeting, dmas: [...(locationTargeting.dmas ?? []), dma] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationTargetingType.City && options.includes(LocationTargetingType.City) && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({ ...locationTargeting, cities: [...(locationTargeting.cities ?? []), city] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationTargetingType.Zip && options.includes(LocationTargetingType.Zip) && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr
              onOptionClick={(zip: PostalCode) =>
                onLocationTargetingChange({
                  ...locationTargeting,
                  postalCodes: [...(locationTargeting.postalCodes ?? []), zip]
                })
              }
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationTargetingType.Map && options.includes(LocationTargetingType.Map) && (
        <>
          <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
          <EuiSpacer size='s' />
          <TargetingMap
            geoCircles={locationTargeting.geoCircles ?? []}
            addCircle={(circle: GeoCircle) => {
              onLocationTargetingChange({ ...locationTargeting, geoCircles: [...(locationTargeting.geoCircles ?? []), circle] })
            }}
            removeCircle={(id: string) => {
              onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.filter(c => c.id !== id) ?? [] })
            }}
            modifyCircle={(circle: GeoCircle) => {
              onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.map(c => (c.id === circle.id ? circle : c)) })
            }}
            geoRectangles={locationTargeting.geoRectangles ?? []}
            addRectangle={(rectangle: GeoRectangle) => {
              onLocationTargetingChange({ ...locationTargeting, geoRectangles: [...(locationTargeting.geoRectangles ?? []), rectangle] })
            }}
            removeRectangle={(id: string) => {
              onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.filter(r => r.id !== id) ?? [] })
            }}
            modifyRectangle={(rectangle: GeoRectangle) => {
              onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.map(r => (r.id === rectangle.id ? rectangle : r)) })
            }}
            geoPolygons={locationTargeting.geoPolygons ?? []}
            addPolygon={(polygon: GeoPolygon) => {
              onLocationTargetingChange({ ...locationTargeting, geoPolygons: [...(locationTargeting.geoPolygons ?? []), polygon] })
            }}
            removePolygon={(id: string) => {
              onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.filter(p => p.id !== id) ?? [] })
            }}
            modifyPolygon={(polygon: GeoPolygon) => {
              onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.map(p => (p.id === polygon.id ? polygon : p)) ?? [] })
            }}
          />
        </>
      )}
    </>
  )
}
