import { CampaignType } from '../../../api/interfaces'
import { Segment } from '../../../api/interfaces/TargetingTemplate'
import ModernAudienceTargeter from '../../targeters/ModernAudienceTargeter'

type SegmentsFormProps = {
  campaignType: CampaignType
  segments: Segment[]
  onSegmentsChange: (segments: Segment[]) => void
}

export const SegmentsForm = ({ campaignType, segments, onSegmentsChange }: SegmentsFormProps) => {
  return (
    <ModernAudienceTargeter
      segments={segments}
      title={''}
      campaignType={campaignType}
      onSegmentClicked={(segment: Segment) => {
        onSegmentsChange([...segments, segment])
      }}
      onAudienceRemoved={segment => {
        onSegmentsChange(segments.filter(s => s.id !== segment.id))
      }}
    />
  )
}
