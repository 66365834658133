import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFormRow, EuiSelectable, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { Account } from '../../api/interfaces'
import { useGetAccountsQuery } from '../../api/rtkQueryApi/platform/accountsApi'
import { showAddAccountFlyout } from '../../app/appSlice'
import { RootState } from '../../app/rootReducer'
import AddAccountFlyout from '../accounts/AddAccountFlyout'
import { ClientPlanCreateStep, setStep } from './CreateClientPlanSlice'

type ClientPickerStepProps = {
  accountId: string
  accountName: string
  setAccountId: (accountId: string) => void
  setAccountName: (accountName: string) => void
  setUrl: (url: string) => void
}

export const ClientPickerStep = ({ accountId, accountName, setAccountId, setAccountName, setUrl }: ClientPickerStepProps) => {
  const accountsQuery = useGetAccountsQuery({})
  const [accountItems, setAccountItems] = useState<EuiSelectableOption[]>([])

  const { addAccountFlyoutOpen } = useSelector((state: RootState) => state.app)

  const dispatch = useDispatch()

  useEffect(() => {
    if (accountsQuery.data) {
      const selectableItems = accountsQuery.data.map<EuiSelectableOption>(account => {
        return {
          label: account.name,
          key: account.id,
          checked: accountId === account.id && accountName === account.name ? 'on' : undefined
        }
      })
      setAccountItems(selectableItems)
    }
  }, [accountsQuery.data])

  const onChange = (options: EuiSelectableOption[]) => {
    const selectedOption = options.find(option => option.checked === 'on')
    if (!selectedOption) {
      setAccountId('')
      setAccountName('')
    } else if (!selectedOption.key) {
      return
    } else if (selectedOption) {
      setAccountId(selectedOption.key)
      setAccountName(selectedOption.label)
      setUrl(accountsQuery.data?.find(account => account.id === selectedOption.key)?.website || '')
    }
    setAccountItems(options)
  }

  const onAddClient = (account: Account) => {
    setAccountId(account.id)
    setAccountName(account.name)
    setUrl(account.website || '')
    dispatch(setStep(ClientPlanCreateStep.SetUrl))
  }

  return (
    <>
      <EuiTitle size={'s'}>
        <h2>Select or Create a Client</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiFormRow label={'Select client'}>
        <EuiSelectable searchable singleSelection aria-label='Client Picker' isLoading={accountsQuery.isLoading || accountsQuery.isFetching} options={accountItems} listProps={{ bordered: true }} onChange={onChange}>
          {(list, search) => (
            <>
              {search}
              {list}
            </>
          )}
        </EuiSelectable>
      </EuiFormRow>
      <EuiSpacer />
      <EuiButton
        iconType={'plusInCircle'}
        onClick={() => {
          dispatch(showAddAccountFlyout())
        }}>
        Create client
      </EuiButton>
      {addAccountFlyoutOpen && <AddAccountFlyout onSubmit={onAddClient} />}
    </>
  )
}
