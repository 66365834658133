import {
  EuiButton,
  EuiFormRow,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel, EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import React from 'react'
import history from '../../../services/HistoryService'

export const ClientPlanWelcomePage : React.FC = () => {

  const onCreateClientPlanClick = () => {
    history.push('/ai/create-plan')
  }

  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1> AdCritter AI</h1>
            </EuiTitle>
            <EuiText>Welcome to AdCritter AI!</EuiText>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiTitle size={'s'}>
            <h2>Let's Get Started</h2>
          </EuiTitle>
          <EuiText>AdCritter AI equips agencies with powerful AI and human insights to win more clients.</EuiText>
          <EuiSpacer/>
          <EuiText>Instead of spending weeks learning your customer’s business inside and out, let AdCritter AI do it in hours.</EuiText>
          <EuiSpacer/>
          <EuiText>Let's get started!</EuiText>
          <EuiSpacer/>
          <EuiFormRow>
            <EuiButton fill onClick={onCreateClientPlanClick}>Create Client Plan</EuiButton>
          </EuiFormRow>
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  )
}