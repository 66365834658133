import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFormRow } from '@elastic/eui'

import { LocationTargetingType } from '../../../../../api/interfaces/CampaignTargeting'
import { RootState } from '../../../../../app/rootReducer'
import { LocationTargetingFields, LocationTargetingForm } from '../../../../../components/targeting/TargetingForm/LocationTargetingForm'
import { GetInternetTargetingTemplateFromCampaign } from '../../../../../utils/TransformTargeting'
import { useUpdateCampaignTargeting } from './useUpdateCampaignTargeting'

export const EditInternetLocationTargeting = () => {
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const targeting = campaign && GetInternetTargetingTemplateFromCampaign(campaign)
  const [locationTargeting, setLocationTargeting] = useState(targeting?.locationTargeting as LocationTargetingFields)
  const [selectedLocationType, setSelectedLocationType] = useState(targeting?.locationTargeting?.locationType ?? LocationTargetingType.Map)
  const { updateCampaignTargeting, importInternetTargetingMutationResult } = useUpdateCampaignTargeting()

  const locationTargetingOptions = [LocationTargetingType.USA, LocationTargetingType.Region, LocationTargetingType.DMA, LocationTargetingType.City, LocationTargetingType.Map, LocationTargetingType.Zip]

  const onSave = async () => {
    if (!campaign) {
      return
    }
    await updateCampaignTargeting(campaign, {
      ...targeting,
      locationTargeting: locationTargeting
    })
  }

  return (
    <>
      <EuiFormRow label={'Choose Location Targeting Strategy'} fullWidth>
        <LocationTargetingForm selectedLocationType={selectedLocationType} onSelectedLocationTypeChange={setSelectedLocationType} includeLocationTypes={locationTargetingOptions} locationTargeting={locationTargeting} onLocationTargetingChange={setLocationTargeting} />
      </EuiFormRow>
      <EuiButton onClick={onSave} isLoading={importInternetTargetingMutationResult.isLoading}>
        Save
      </EuiButton>
    </>
  )
}
