import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFieldText, EuiFormRow, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiRadioGroup, EuiRadioGroupOption, EuiTitle } from '@elastic/eui'

import { useCreatCampaignSimpleMutation } from '../../../api/rtkQueryApi/platform/campaignsApi'
import { RootState } from '../../../app/rootReducer'
import history from '../../../services/HistoryService'
import { BuilderCampaignType } from '../../../workflows/CampaignCreateWorkflow'
import { setCampaign as setGlobalCampaign } from '../../campaigns/campaign/campaignSlice'

interface ISimpleCampaign {
  campaignName: string
  campaignType: BuilderCampaignType
}

export const SimpleCampaignPage: React.FC = () => {
  const [createCampaign, createCampaignRequest] = useCreatCampaignSimpleMutation()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [campaign, setCampaign] = React.useState<ISimpleCampaign>({
    campaignName: '',
    campaignType: BuilderCampaignType.Display
  })

  const dispatch = useDispatch()

  const radios: EuiRadioGroupOption[] = [
    {
      id: BuilderCampaignType.Display,
      label: 'Internet'
    },
    {
      id: BuilderCampaignType.TV,
      label: 'CTV'
    },
    {
      id: BuilderCampaignType.Billboard,
      label: 'Digital Billboards'
    }
  ]

  // TODO: fix this routing logic.

  const onCreateClick = async () => {
    const newCampaign = await createCampaign({
      accountId: currentAccount!.id,
      campaignName: campaign.campaignName,
      campaignType: campaign.campaignType
    }).unwrap()

    dispatch(setGlobalCampaign(newCampaign))

    history.push(`/campaigns/edit/${newCampaign.id}/details`)
  }

  return (
    <EuiPage className='euiPage--platform'>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>New Campaign</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiFormRow label={'Campaign Name'}>
            <EuiFieldText value={campaign.campaignName} onChange={e => setCampaign({ ...campaign, campaignName: e.target.value })} />
          </EuiFormRow>
          <EuiFormRow label={'Campaign Type'}>
            <EuiRadioGroup options={radios} idSelected={campaign.campaignType} onChange={id => setCampaign({ ...campaign, campaignType: id as BuilderCampaignType })} />
          </EuiFormRow>
          <EuiFormRow>
            <EuiButton onClick={onCreateClick} isLoading={createCampaignRequest.isLoading} disabled={campaign.campaignName.length < 1}>
              Create
            </EuiButton>
          </EuiFormRow>
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  )
}
