import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiCallOut,
  EuiLoadingContent,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiSpacer,
  EuiTabbedContent,
  EuiTitle
} from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { CampaignActiveStatus, CampaignStatus } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { CampaignDetailsDrawer } from 'features/campaigns/CampaignDetailsDrawer'
import { ModernTargetingDrawer } from 'features/campaigns/campaign/ModernTargetingDrawer'
import { RetargetingDrawer } from 'features/campaigns/campaign/RetargetingDrawer'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import ActivateCallOut from './ActivateCallOut'
import { default as BillboardAdsDrawer } from './billboards/AdsDrawer'
import BillboardTargetingDrawer from './billboards/BillboardTargetingDrawer'
import { fetchCampaign } from './campaignSlice'
import { default as InternetAdPlacementDrawer } from './internet/AdPlacementDrawer'
import { default as InternetAdsDrawer } from './internet/AdsDrawer'
import AdvancedAudienceDrawer from './internet/AdvancedAudienceDrawer'
import AdvancedLocationDrawer from './internet/AdvancedLocationDrawer'
import { default as InternetDetailsMonthlyImpressionsDrawer } from './internet/DetailsMonthlyImpressionsDrawer'
import StandardDrawer from './internet/StandardDrawer'
import VistaDrawer from './internet/VistaDrawer'
import { default as TvCommercialPlacementDrawer } from './tv/AdPlacementDrawer'
import { default as TvCommercialsDrawer } from './tv/AdsDrawer'
import TvTargetingDrawer from './tv/TvTargetingDrawer'
import { useOrganizationPermissions } from '../../../providers/OrganizationPermissionsContext'

interface EditCampaignPageParams {
  campaignId: string
}

const EditCampaignPage: React.FC = () => {
  const dispatch = useDispatch()
  let { campaignId } = useParams<EditCampaignPageParams>()
  let location = useLocation()
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const { isLoadingCampaign, campaign, ads, isLoadingAds } = useSelector((state: RootState) => state.campaign)
  const [tabs, setTabs] = useState<EuiTabbedContentTab[]>([])
  const [showCustomAdsWaiting, setShowCustomAdsWaiting] = useState(false)
  const [showOutroWaiting, setShowOutroWaiting] = useState(false)
  const [showActivate, setShowActivate] = useState(false)
  const whiteLabel = useWhiteLabel()
  const { hasPermission } = useOrganizationPermissions()

  useEffect(() => {
    if (currentAccount && campaignId) dispatch(fetchCampaign(currentAccount.id, campaignId))
  }, [currentAccount, campaignId, dispatch])

  useEffect(() => {
    if (currentOrganization && currentAccount) {
      setShowActivate(!isLoadingCampaign && !!campaign && campaign.status === CampaignStatus.SavedForLater && !isLoadingAds && !!ads && ads.length > 0 && (currentAccount.hasCustomBusinessType || !currentAccount.hasCustomBusinessType))
      setShowCustomAdsWaiting(!!campaign && campaign.status === CampaignStatus.WaitingForCustomAds)
      setShowOutroWaiting(!!campaign && campaign.status === CampaignStatus.WaitingForTVOutro && campaign.type === 'TV')
    }
  }, [isLoadingCampaign, campaign, isLoadingAds, ads, currentOrganization, currentAccount])

  useEffect(() => {
    if (!campaign) return

    let tabs: Array<EuiTabbedContentTab> = []
    if (campaign.type === 'Internet') {
      tabs.push({
        id: 'ads',
        name: 'Campaign Ads',
        content: <InternetAdsDrawer />
      })

      if (campaign.targetingTemplate === 'Standard') {
        tabs.push({
          id: 'standard',
          name: 'Smart Targeter',
          content: <StandardDrawer />
        })
      }
      if (campaign.targetingTemplate === 'Shopify' || campaign.targetingTemplate === 'Advanced' || campaign.targetingTemplate === 'ShapeAdvanced' || (campaign.targetingTemplate === 'Recruitment' && campaign.recruitmentTargeting!.hasAdvancedTargeting)) {
        if (campaign.advancedTargeting!.advancedTargetingType === 'Location') {
          if (campaign.advancedTargeting!.locations) {
            tabs.push({
              id: 'locationBased',
              name: 'Location Based Targeting',
              content: <ModernTargetingDrawer />
            })
          } else {
            tabs.push({
              id: 'locationBased',
              name: 'Location Based Targeting',
              content: <AdvancedLocationDrawer />
            })
          }
        }
        if (campaign.advancedTargeting!.advancedTargetingType === 'Audience') {
          if (campaign.advancedTargeting!.locations) {
            tabs.push({
              id: 'audienceBased',
              name: 'Audience Based Targeting',
              content: <ModernTargetingDrawer />
            })
          } else {
            tabs.push({
              id: 'audienceBased',
              name: 'Audience Based Targeting',
              content: <AdvancedAudienceDrawer />
            })
          }
        }
        if (campaign.advancedTargeting!.advancedTargetingType === 'Retarget') {
          tabs.push({
            id: 'retargeting',
            name: 'Retargeting',
            content: <RetargetingDrawer />
          })
        }
      }
      if (campaign.targetingTemplate === 'Vista') {
        tabs.push({
          id: 'locationBased',
          name: 'Targeting',
          content: <VistaDrawer />
        })
      }

      if (!whiteLabel?.isVista()) {
        tabs.push({
          id: 'placement',
          name: 'Ad Placement',
          content: <InternetAdPlacementDrawer />
        })
      }

      if (campaign.targetingTemplate === 'Shape' || campaign.targetingTemplate === 'ShapeAdvanced') {
        tabs.push({
          id: 'details',
          name: 'Campaign Details',
          content: <InternetDetailsMonthlyImpressionsDrawer />
        })
      } else {
        tabs.push({
          id: 'details',
          name: 'Campaign Details',
          content: <CampaignDetailsDrawer campaign={campaign} />
        })
      }
    }

    if (campaign.type === 'TV') {
      tabs.push({
        id: 'ads',
        name: 'Campaign Ads',
        content: <TvCommercialsDrawer />
      })

      if (campaign.targetingTemplate === 'TV' || campaign.targetingTemplate === 'ShopifyTV') {
        if (campaign.tvTargeting?.locations) {
          tabs.push({
            id: 'tv',
            name: 'TV Targeting',
            content: <ModernTargetingDrawer />
          })
        } else {
          tabs.push({
            id: 'tv',
            name: 'TV Targeting',
            content: <TvTargetingDrawer />
          })
        }
      }

      tabs.push({
        id: 'placement',
        name: 'Ad Placement',
        content: <TvCommercialPlacementDrawer />
      })

      tabs.push({
        id: 'details',
        name: 'Campaign Details',
        content: <CampaignDetailsDrawer campaign={campaign} />
      })
    }

    if (campaign.type === 'Billboard') {
      tabs.push({
        id: 'ads',
        name: 'Campaign Ads',
        content: <BillboardAdsDrawer />
      })

      if (campaign.targetingTemplate === 'Billboard' || campaign.targetingTemplate === 'ShopifyBillboard') {
        if (campaign.billboardTargeting?.locations) {
          tabs.push({
            id: 'tv',
            name: 'Billboard Targeting',
            content: <ModernTargetingDrawer />
          })
        } else {
          tabs.push({
            id: 'tv',
            name: 'Billboard Targeting',
            content: <BillboardTargetingDrawer />
          })
        }
      }

      tabs.push({
        id: 'details',
        name: 'Campaign Details',
        content: <CampaignDetailsDrawer campaign={campaign} />
      })
    }

    setTabs(tabs)
  }, [campaign, campaignId, location.pathname, whiteLabel, hasPermission])

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaign</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>
              Campaign{' '}
              {!isLoadingCampaign && campaign && (
                <React.Fragment>
                  - {campaign.name}
                  {campaign.hasBundle && !campaign.isMatching ? (
                    <>
                      {' '}
                      <EuiBadge color='accent'>Plan</EuiBadge>
                    </>
                  ) : (
                    ''
                  )}
                  {campaign.isMatching ? (
                    <>
                      {' '}
                      <EuiBadge color='success'>Matching</EuiBadge>
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )}
            </h1>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection>
          <EuiButtonEmpty id='back' onClick={() => history.push('/campaigns')} iconType='arrowLeft' iconSide='left' size='s'>
            Back To All Campaigns
          </EuiButtonEmpty>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      {isLoadingCampaign && (
        <EuiPanel grow={false}>
          <EuiLoadingContent />
        </EuiPanel>
      )}
      {!isLoadingCampaign && (
        <EuiPanel grow={false}>
          {showActivate && (
            <React.Fragment>
              <ActivateCallOut campaignId={campaignId} amount={campaign!.budget} campaignType={campaign?.type ?? 'Internet'} budgetFrequency={campaign?.budgetFrequency ?? 'Weekly'} />
              <EuiSpacer />
            </React.Fragment>
          )}
          {campaign?.activeStatus === CampaignActiveStatus.Inactive && campaign?.status !== CampaignStatus.SavedForLater && campaign?.status !== CampaignStatus.WaitingForCustomAds && campaign?.status !== CampaignStatus.WaitingForTVOutro && (
            <React.Fragment>
              <EuiCallOut title='Campaign ended and will not run ads.' iconType={'minusInCircleFilled'} />
              <EuiSpacer />
            </React.Fragment>
          )}
          {showCustomAdsWaiting && (
            <React.Fragment>
              <EuiCallOut title='This campaign is waiting on Custom Ads to be created.' iconType='globe' />
              <EuiSpacer />
            </React.Fragment>
          )}
          {showOutroWaiting && (
            <React.Fragment>
              <EuiCallOut title='This campaign is waiting on your Outro to be created.' iconType='play' />
              <EuiSpacer />
            </React.Fragment>
          )}
          {tabs && tabs.length > 0 && <EuiTabbedContent tabs={tabs} />}
        </EuiPanel>
      )}
    </React.Fragment>
  )
}

export default EditCampaignPage
