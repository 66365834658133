import { useState } from 'react'

import { EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { CampaignType } from '../../../api/interfaces'
import { BaseTargeting, Segment, TvTargeting } from '../../../api/interfaces/TargetingTemplate'
import { CampaignDeliveryForm } from './CampaignDeliveryForm'
import { SegmentsForm } from './SegmentsForm'

type TvTargetingFormProps = {
  tvTargeting: TvTargeting
  onTvTargetingChanged: (tvTargeting: TvTargeting) => void
}

export const TvTargetingForm = ({ tvTargeting, onTvTargetingChanged }: TvTargetingFormProps) => {
  const [editCampaignDelivery, setEditCampaignDelivery] = useState(false)

  const onSegmentsChange = (segments: Segment[]) => {
    onTvTargetingChanged({
      ...tvTargeting,
      segments
    })
  }

  const onCampaignDeliveryChange = (campaignDelivery: BaseTargeting) => {
    onTvTargetingChanged({
      ...tvTargeting,
      ...campaignDelivery
    })
  }

  return (
    <>
      <SegmentsForm campaignType={CampaignType.TV} segments={tvTargeting.segments ?? []} onSegmentsChange={onSegmentsChange} />
      <EuiSpacer />
      <EuiSwitch label={'Edit Campaign Delivery Strategy'} checked={editCampaignDelivery} onChange={() => setEditCampaignDelivery(!editCampaignDelivery)} />
      <EuiSpacer />
      {editCampaignDelivery && (
        <>
          <EuiTitle size={'s'}>
            <h3>Campaign Delivery Strategy</h3>
          </EuiTitle>
          <EuiSpacer />
          <CampaignDeliveryForm targeting={tvTargeting} onTargetingChanged={onCampaignDeliveryChange} campaignType={CampaignType.TV} />
        </>
      )}
    </>
  )
}
