import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'
import { TextSize } from '@elastic/eui/src/components/text/text'

export interface IAcBadgeText {
  text: string
  textSize?: TextSize
  badgeColor?: string
  badgeText: string
}

export const AcBadgeText: React.FC<IAcBadgeText> = props => {
  return (
    <EuiFlexGroup gutterSize={'m'}>
      <EuiFlexItem grow={false}>
        <EuiText size={props.textSize}><p>{props.text}</p></EuiText>
      </EuiFlexItem>
      <EuiFlexItem style={{paddingTop: 2}}>
        <EuiBadge color={props.badgeColor}>{props.badgeText}</EuiBadge>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}