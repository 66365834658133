import React from 'react'
import { EuiCallOut } from '@elastic/eui'
import { ActivateCampaignContent } from './ActivateCampaignContent'

interface ActivatePageProps {
  campaignId: string
  amount: number
  campaignType: string
  budgetFrequency: string
}

const ActivateCallOut = ({ campaignId, amount, campaignType }: ActivatePageProps) => {
  return (
    <React.Fragment>
      <EuiCallOut title='This campaign still needs to be activated before it will run.' iconType='play' color='success'>
        <ActivateCampaignContent campaignId={campaignId} campaignType={campaignType} amount={amount} />
      </EuiCallOut>
    </React.Fragment>
  )
}

export default ActivateCallOut
