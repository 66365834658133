import { combineReducers } from '@reduxjs/toolkit'

import { platformApi } from 'api/rtkQueryApi/platform/platformApi'
import searchImageReducer from 'components/chooseImage/searchImageSlice'
import customAdSuggestionReducer from 'components/suggestedAds/customAdsSlice'
import generalSuggestionReducer from 'components/suggestedAds/generalSuggestionSlice'
import targetingMapReducer from 'components/targetingMapSlice'
import accountReducer from 'features/accounts/accountSlice'
import { CampaignCreateSlice } from 'features/builders/CampaignCreateSlice'
import defaultTargetingTemplateReducer from 'features/builders/DefaultTargetingSlice'
import builderBillboardRetailReducer from 'features/builders/billboards/retail/builderBillboardRetailSlice'
import builderInternetRetailReducer from 'features/builders/internet/retail/builderInternetRetailSlice'
import builderInternetVistaReducer from 'features/builders/internet/vista/builderInternetVistaSlice'
import builderTvRetailReducer from 'features/builders/tv/retail/builderTvRetailSlice'
import adReducer from 'features/campaigns/ad/adSlice'
import campaignReducer from 'features/campaigns/campaign/campaignSlice'
import campaignsReducer from 'features/campaigns/campaigns/campaignsSlice'
import createAdBillboardRetailReducer from 'features/campaigns/createAd/billboards/retail/createAdBillboardRetailSlice'
import createAdInternetRetailReducer from 'features/campaigns/createAd/internet/retail/createAdInternetRetailSlice'
import createAdTvRetailReducer from 'features/campaigns/createAd/tv/retail/createAdTvRetailSlice'
import billboardOverviewReducer from 'features/dashboard/billboardOverviewSlice'
import dashboardReducer from 'features/dashboard/dashboardSlice'
import overviewReducer from 'features/dashboard/overviewSlice'
import tvOverviewReducer from 'features/dashboard/tvOverviewSlice'
import organizationReducer from 'features/organization/organizationSlice'
import createClientPlanReducer from 'features/planner/CreateClientPlanSlice'
import reportsReducer from 'features/reports/reportsSlice'

import appReducer from './appSlice'
import dictionaryReducer from './dictionarySlice'
import eventsReducer from './eventsSlice'

const rootReducer = combineReducers({
  app: appReducer,
  dictionary: dictionaryReducer,
  organization: organizationReducer,
  account: accountReducer,
  campaigns: campaignsReducer,
  campaign: campaignReducer,
  ad: adReducer,
  createAdInternetRetail: createAdInternetRetailReducer,
  createAdBillboardRetail: createAdBillboardRetailReducer,
  createAdTvRetail: createAdTvRetailReducer,
  builderInternetRetail: builderInternetRetailReducer,
  builderTvRetail: builderTvRetailReducer,
  builderBillboardRetail: builderBillboardRetailReducer,
  builderInternetVista: builderInternetVistaReducer,
  searchImage: searchImageReducer,
  dashboard: dashboardReducer,
  overview: overviewReducer,
  tvOverview: tvOverviewReducer,
  billboardOverview: billboardOverviewReducer,
  generalSuggestion: generalSuggestionReducer,
  customAdSuggestion: customAdSuggestionReducer,
  reports: reportsReducer,
  targetingMap: targetingMapReducer,
  campaignCreateWorkflow: CampaignCreateSlice.reducer,
  events: eventsReducer,
  defaultTargetingTemplate: defaultTargetingTemplateReducer,
  createClientPlan: createClientPlanReducer,
  [platformApi.reducerPath]: platformApi.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
